import {
  Button,
  Card,
  FileUploader,
  Select,
  Spacer,
  Typography,
  Flex,
  Icon,
  ConfirmModal,
} from "ingred-ui";
import { OptionType } from "ingred-ui/dist/components/Select/Select";
import * as React from "react";

import { Helmet } from "../../../containers/Helmet";
import { CSVImportableDemandAccounts } from "../../../domain/csvImport";
import { postCSV } from "../../../store/modules/csvImport/actions/postCSVAction";
import { enqueueSystemNotification } from "../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { SystemNotificationVariant } from "../../../store/modules/systemNotification/constants";
import { DispatchableAction } from "../../../store/utils/dispatchable";

import * as Styled from "./styled";

type InjectProps = {
  enqueueSystemNotification: DispatchableAction<
    typeof enqueueSystemNotification
  >;
  postCSV: DispatchableAction<typeof postCSV>;
  fetchCSVImportableDemandAccountsRequesting: boolean;
  uploadSucceeded: boolean;
  csvImportableDemandAccounts: CSVImportableDemandAccounts[];
};

type Props = {};

type InjectedProps = Props & InjectProps;

const CSVImporter: React.FunctionComponent<InjectedProps> = ({
  postCSV,
  enqueueSystemNotification,
  uploadSucceeded,
  fetchCSVImportableDemandAccountsRequesting,
  csvImportableDemandAccounts,
}) => {
  const [selectedDemandAccount, setSelectedDemandAccount] = React.useState<
    number | null
  >(null);
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [showSuccessToast, setShowSuccessToast] = React.useState<boolean>(
    false,
  );
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const accounts = csvImportableDemandAccounts.map(
    (d) => new Option(d.demand.name + ` (アカウントID:${d.id})`, String(d.id)),
  );

  const handleChange = (value: any) => {
    setSelectedDemandAccount(parseInt((value as OptionType<string>).value));
  };

  const handleUploadChange = (
    _event: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLElement>,
    files: FileList | null,
  ) => {
    if (files != null) {
      const f = files.item(0);
      setSelectedFile(f);
    }
  };

  const handleUpload = () => {
    if (selectedDemandAccount == null) {
      enqueueSystemNotification({
        message: "CSVファイルをアップロードする前にデマンドを選択してください",
        variant: SystemNotificationVariant.ERROR,
      });
      return;
    }
    if (selectedFile == null) {
      enqueueSystemNotification({
        message: "CSVファイルが選択されていません", // ファイル選択時にアップロードが発生するので、本来は発生しないエラー
        variant: SystemNotificationVariant.ERROR,
      });
      return;
    }

    setModalOpen(true);

    postCSV({
      demand_account_id: selectedDemandAccount,
      file: selectedFile,
    })
      .then(() => {
        setShowSuccessToast(true);
        setModalOpen(false);
      })
      .catch(() => {
        setModalOpen(false);
      });
  };

  React.useEffect(() => {
    if (uploadSucceeded && showSuccessToast) {
      enqueueSystemNotification({
        message: "レポートの取り込みが完了しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setShowSuccessToast(false);
    }
  }, [uploadSucceeded, showSuccessToast, enqueueSystemNotification]);

  return (
    <>
      <Helmet title="CSVインポート機能 | DATA STRAP" />
      <Spacer p={2}>
        <Typography>
          CSVファイルをアップロードする際は、以下の列を必ず含めてください:
          <strong>「日付」「サイト」「枠」「imps」「clicks」「price」</strong>
        </Typography>
        <Typography>
          文字コードは<strong>UTF-8のみ対応</strong>しています。
        </Typography>
        <Spacer pt={1} />
        <Flex display="flex" alignItems="center">
          <Spacer pr={1}>
            <Icon name="exclamation" size="md" />
          </Spacer>
          <Typography>
            インポートされたデータは、
            <strong>アップロード日から30日前までのデータ</strong>がDATA
            STRAPに反映 されます。 通常は<strong>翌日には反映</strong>
            されますのでご了承ください。
          </Typography>
        </Flex>
        <Typography>
          <strong>30日以上前、または特定の過去期間のデータ</strong>
          をアップロードする場合は、 担当者にご連絡ください。
        </Typography>
        <Spacer pt={3} />
      </Spacer>
      <Styled.Container>
        <Spacer p={2}>
          例:
          <Card p={2}>
            <Spacer p={1}>
              日付,サイト,枠,imps,clicks,price
              <br />
              2021-1-1,DATASTRAPサイト,枠1,100,1,12.345
              <br />
              2021-1-1,DATASTRAPサイト,枠2,200,2,24.345
              <br />
              2021-1-2,DATASTRAPサイト,枠1,150,2,48.234
            </Spacer>
          </Card>
          <Spacer py={1}>
            デマンドアカウントを選択
            {fetchCSVImportableDemandAccountsRequesting == false && (
              <Select
                name="demand_account_id"
                options={accounts}
                onChange={handleChange}
              />
            )}
          </Spacer>
          <Spacer py={1}>
            <FileUploader
              title={
                selectedFile != null
                  ? "選択中: " + selectedFile.name
                  : "ドラッグ＆ドロップするか、クリックしてファイルを選択"
              }
              accept="*.csv"
              description=""
              onSelectFiles={handleUploadChange}
            />
          </Spacer>
          <Spacer py={1}>
            <Button size="large" onClick={handleUpload}>
              アップロード
            </Button>
          </Spacer>
        </Spacer>
      </Styled.Container>
      <ConfirmModal
        isOpen={modalOpen}
        title="レポートアップロード中"
        loading={true}
      >
        <Spacer pt={1} />
        <Typography>しばらく操作せずにお待ちください。</Typography>
        <Typography>
          レポートの日付範囲が長期間の場合、アップロードに時間がかかることがあります。
        </Typography>
      </ConfirmModal>
    </>
  );
};

export { CSVImporter };
