import { Domain } from "api-types";
import {
  Typography,
  Spacer,
  Flex,
  Icon,
  useTheme,
  Button,
  DataTable,
  Column,
  Select,
  Portal,
  FixedPanel,
  Spinner,
} from "ingred-ui";
import { OptionType } from "ingred-ui/dist/components/Select/Select";
import React from "react";

import { Helmet } from "../../../../containers/Helmet";
import { CompanyAttribute } from "../../../../domain/companyAttribute";
import { GamAdUnit } from "../../../../domain/gamAdUnit";
import { GamLineItem } from "../../../../domain/gamLineItem";
import { useIntersectionObserver } from "../../../../hooks/useIntersectionObserver";
import { patchYieldSetting } from "../../../../store/modules/gamLineItem/actions/patchYieldSettingAction";
import { enqueueSystemNotification } from "../../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { SystemNotificationVariant } from "../../../../store/modules/systemNotification/constants";
import { DispatchableAction } from "../../../../store/utils/dispatchable";
import {
  hasAuthorityRequirementsByUserRole,
  FuncAuthorityConstraint,
} from "../../../../utils/AuthUtils";
import { Link } from "../../../atoms/Link";
import { Breadcrumbs } from "../../../elements/Breadcrumbs";
import { PageContainer } from "../../../elements/PageContainer";
import { PageContent } from "../../../elements/PageContent";
import { Popover } from "../../../elements/Popover";

import * as Styled from "./styled";

function createSourceData(gamLineItems: GamLineItem[]): SourceData[] {
  return gamLineItems.map((lineItem) => ({
    id: lineItem.id,
    name: lineItem.original_line_item_name,
    yieldSetting: lineItem.gam_line_item_yield_setting
      ? lineItem.gam_line_item_yield_setting.yield_type_name
      : null,
  }));
}

type InjectProps = {
  currentCompany: CompanyAttribute;
  gamLineItems: GamLineItem[];
  parentGamAdUnit: GamAdUnit;
  userRole: Domain.UserRole;
  enqueueSystemNotification: DispatchableAction<
    typeof enqueueSystemNotification
  >;
  patchYieldSetting: DispatchableAction<typeof patchYieldSetting>;
  patchRequesting: boolean;
  patchSucceeded: boolean;
};

export type Props = {
  parentGamAdUnitId: number;
};

export type SourceData = {
  id: number;
  name: string;
  yieldSetting: Domain.YieldType | null;
};

type InjectedProps = Props & InjectProps;

const GamLineItems: React.FunctionComponent<InjectedProps> = ({
  currentCompany,
  gamLineItems,
  parentGamAdUnit,
  userRole,
  enqueueSystemNotification,
  patchYieldSetting,
  patchRequesting,
  patchSucceeded,
}) => {
  const theme = useTheme();

  const buttonContainerRef = React.useRef<HTMLDivElement>(null);
  const showPanel = !useIntersectionObserver(buttonContainerRef);

  const [sourceData, setSourceData] = React.useState<SourceData[]>([]);
  const [searchText, setSearchText] = React.useState("");
  const [patchToasted, setPatchToasted] = React.useState(patchSucceeded);
  const yieldTypeOptions: OptionType<Domain.YieldType>[] = [
    {
      label: "配信比率",
      value: "cpm_only_update_creative_weight",
    },
    {
      label: "CPM+配信比率",
      value: "cpm",
    },
    {
      label: "停止",
      value: "suspend",
    },
  ];

  React.useEffect(() => {
    if (!patchToasted && patchSucceeded) {
      enqueueSystemNotification({
        message: "変更を保存しました。",
        variant: SystemNotificationVariant.SUCCESS,
      });
      setPatchToasted(true);
    }
  }, [enqueueSystemNotification, patchToasted, patchSucceeded]);

  // if (!patchRequesting) はアニメーションのチャタリング防止用
  React.useEffect(() => {
    if (!patchRequesting) {
      const data = createSourceData(gamLineItems);
      const searchedItems = data.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase()),
      );
      setSourceData(searchedItems);
    }
  }, [gamLineItems, searchText, patchRequesting]);

  const handleYieldTypeChange = (id: number) => (event: any) => {
    const target = sourceData.map((data) => {
      if (data.id == id) {
        data.yieldSetting = (event as OptionType<Domain.YieldType>).value;
      }
      return data;
    });
    setSourceData(target);
  };

  function getColumns(userRole: Domain.UserRole) {
    const columns: Column<SourceData>[] = [
      {
        name: "申込情報",
        selector: (data) => data.name,
        sortable: true,
        renderCell: (data) => (
          <Flex display="flex" alignItems="center">
            <Spacer pr={1}>
              <Icon name="folder" color={theme.palette.primary.main} />
            </Spacer>
            <Link
              href={`/company/${currentCompany.company.id}/settings/gam_ad_creatives_manager/units/${parentGamAdUnit.id}/line_items/${data.id}`}
            >
              <Typography color="primary">{data.name}</Typography>
            </Link>
          </Flex>
        ),
      },
    ];

    if (
      hasAuthorityRequirementsByUserRole(
        FuncAuthorityConstraint.DA_YIELD,
        userRole,
      )
    ) {
      columns.push({
        name: "DA自動最適化",
        selector: (data) => data.id,
        headerCell: (
          <Flex display="flex">
            DA自動最適化
            <Spacer pl={1} />
            <Popover position="left-start">
              <Spacer pb={1}>
                <Typography weight="bold">作動条件について</Typography>
              </Spacer>
              <Typography size="sm">
                GAMの設定でイールドが可能になっていないと、DA自動最適化はできません。
              </Typography>
            </Popover>
          </Flex>
        ),
        renderCell: (data) => (
          <Flex display="flex" alignItems="center">
            {["not_available", null].includes(data.yieldSetting) ? (
              <Styled.ImpossibleLabel>
                <Typography
                  size="xs"
                  weight="bold"
                  color={theme.palette.danger.main}
                >
                  設定不可
                </Typography>
              </Styled.ImpossibleLabel>
            ) : (
              <Styled.SelectContainer>
                <Select
                  isClearable={false}
                  value={
                    yieldTypeOptions.filter(
                      (y) => y.value === data.yieldSetting,
                    )[0]
                  }
                  options={yieldTypeOptions}
                  onChange={handleYieldTypeChange(data.id)}
                />
              </Styled.SelectContainer>
            )}
          </Flex>
        ),
        width: "280px",
      });
    }

    return columns;
  }

  const handleClick = () => {
    const configurableData = sourceData.filter(
      (data) => !["not_available", null].includes(data.yieldSetting),
    );

    patchYieldSetting(
      configurableData.map((data) => ({
        gam_line_item_id: data?.id as number,
        yield_type_name: data?.yieldSetting as Domain.YieldType,
      })),
    );

    setPatchToasted(false);
  };

  if (!parentGamAdUnit) {
    return null;
  }

  return (
    <>
      <Helmet
        title={`${parentGamAdUnit.original_ad_unit_name}の申込情報一覧 | DATA
          STRAP`}
      />
      <Portal>
        <FixedPanel isOpen={showPanel}>
          <Spacer py={2} px={3}>
            <Flex display="flex" justifyContent="flex-end">
              <Button size="large" inline={true} onClick={handleClick}>
                変更を保存する
              </Button>
            </Flex>
          </Spacer>
        </FixedPanel>
      </Portal>
      <PageContainer>
        <Flex display="flex" justifyContent="space-between" alignItems="center">
          <Breadcrumbs
            items={[
              {
                title: "GAM広告クリエイティブ管理",
                link: "/settings/gam_ad_creatives_manager",
              },
              {
                title: parentGamAdUnit.original_ad_unit_name,
              },
            ]}
          />
          {hasAuthorityRequirementsByUserRole(
            FuncAuthorityConstraint.DA_YIELD,
            userRole,
          ) && (
            // TODO: <Button />にもforwardRefを適用する
            <div ref={buttonContainerRef}>
              <Button inline={true} onClick={handleClick}>
                変更を保存する
              </Button>
            </div>
          )}
        </Flex>
        <Spacer pt={3} />
        <PageContent
          title={`${parentGamAdUnit.original_ad_unit_name}の申込情報一覧`}
          placeholder="申込情報を検索"
          searchText={searchText}
          onChange={setSearchText} // eslint-disable-line react/jsx-handler-names
        >
          <Spacer pt={2} px={2}>
            <DataTable
              dataKey={"id"}
              itemEmptyProps={{
                title: "該当する申込情報がありません。",
              }}
              enablePagination={true}
              data={sourceData}
              tabs={
                hasAuthorityRequirementsByUserRole(
                  FuncAuthorityConstraint.DA_YIELD,
                  userRole,
                )
                  ? [
                      {
                        label: "DA自動最適化が可能",
                        filter: (data) =>
                          data.filter(
                            (item) =>
                              !["not_available", null].includes(
                                item.yieldSetting,
                              ),
                          ),
                      },
                      {
                        label: "DA自動最適化が不可能",
                        filter: (data) =>
                          data.filter((item) =>
                            ["not_available", null].includes(item.yieldSetting),
                          ),
                      },
                    ]
                  : undefined
              }
              columns={getColumns(userRole)}
            />
          </Spacer>
        </PageContent>
        {patchRequesting && (
          <Styled.LoadingContainer>
            <Spinner />
          </Styled.LoadingContainer>
        )}
      </PageContainer>
    </>
  );
};

export { GamLineItems };
