import * as React from "react";
import { connect } from "react-redux";

import { AuthedAppShell } from "../../../../containers/AuthedAppShell";
import { CompanyAttribute } from "../../../../domain/companyAttribute";
import { GamAdUnit } from "../../../../domain/gamAdUnit";
import {
  RouteActionContext,
  RouteActionPayload,
} from "../../../../routing/types";
import { getUserRole } from "../../../../store/modules/auth/selectors/authSelector";
import { getGamAdUnitById } from "../../../../store/modules/gamAdUnit/selectors/gamAdUnitSelector";
import { fetchGamLineItems as fetchGamLineItemsAction } from "../../../../store/modules/gamLineItem/actions/fetchGamLineItemsAction";
import { patchYieldSetting as patchYieldSettingAction } from "../../../../store/modules/gamLineItem/actions/patchYieldSettingAction";
import { getGamLineItemsByGamAdUnitId } from "../../../../store/modules/gamLineItem/selectors/gamLineItemSelector";
import { enqueueSystemNotification } from "../../../../store/modules/systemNotification/actions/EnqueueSystemNotificaitonAction";
import { RootState } from "../../../../store/reducer";
import { dispatchable } from "../../../../store/utils/dispatchable";
import { forward404 } from "../../../../utils/HttpUtils";

import { GamLineItems as Component, Props } from "./GamLineItems";

const GamLineItems = connect(
  (state: RootState, ownProps: Props) => ({
    currentCompany: state.user.currentCompany as CompanyAttribute, // /company/:id 以下なので選択済み
    gamLineItems: getGamLineItemsByGamAdUnitId(
      state,
      ownProps.parentGamAdUnitId,
    ),
    parentGamAdUnit: getGamAdUnitById(
      state,
      ownProps.parentGamAdUnitId,
    ) as GamAdUnit,
    userRole: getUserRole(state),
    patchRequesting: state.gamLineItem.yieldSetting.patch.requesting,
    patchSucceeded: state.gamLineItem.yieldSetting.patch.success,
  }),
  (dispatch) => ({
    enqueueSystemNotification: dispatchable(
      dispatch,
      enqueueSystemNotification,
    ),
    patchYieldSetting: dispatchable(dispatch, patchYieldSettingAction),
  }),
)(Component);

export async function action({
  store,
  firstOrPush,
  params,
}: RouteActionContext): Promise<RouteActionPayload> {
  const unitId = parseInt(params["unit_id"] as string, 10);
  if (firstOrPush) {
    await store.dispatch(
      fetchGamLineItemsAction({
        gam_ad_unit_id: unitId,
      }),
    );
  }

  const state = store.getState();
  const gamAdUnit = getGamAdUnitById(state, unitId);

  if (gamAdUnit == null) {
    throw forward404();
  }

  return {
    layout: {
      component: AuthedAppShell,
      props: {},
    },
    content: <GamLineItems parentGamAdUnitId={unitId} />,
  };
}
