import { Domain, Request } from "api-types";
import { ConfirmModal, Select, Spacer, TextField, Typography } from "ingred-ui";
import { OptionType } from "ingred-ui/dist/components/Select/Select";
import * as React from "react";
import { useForm } from "react-hook-form";

import { colors } from "../../../../../../styles/variables/color";
import { registerIui } from "../../../../../../utils/registerIui";

import * as Styled from "./styled";

type CreateDemandForm = {
  demand_id: string;
  [key: string]: string;
};
type Props = {
  isOpen: boolean;
  onClose: () => void;
  demandForms: Domain.DemandForm[];
  onSubmit?: (data: Request.DemandAccount.Create) => void;
  loading?: boolean;
};

const CreateModal: React.FunctionComponent<Props> = ({
  isOpen,
  onClose,
  demandForms,
  onSubmit,
  loading = false,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<CreateDemandForm>({
    defaultValues: {
      demand_id: "",
    },
  });

  React.useEffect(() => {
    register("demand_id");
  }, [register]);

  const [selectedDemand, setSelectedDemand] = React.useState<OptionType | null>(
    null,
  );
  const handleModalDataReset = () => {
    reset();
  };

  const handleChange = (selectedOption: any) => {
    reset({ demand_id: selectedOption.value }); //デマンド切替時に前フォームの項目が残らないようにリセットをかけている。
    setSelectedDemand(selectedOption);
  };

  const getDemandFormsById = (demandId: string) => {
    const demandAccountSetting = demandForms.find(
      (setting) => setting.demand.id === parseInt(demandId, 10),
    );
    if (demandAccountSetting) {
      return demandAccountSetting.form_columns;
    }
    return [];
  };

  const getDemandDescriptionById = (demandId: string) => {
    const demandAccountSetting = demandForms.find(
      (setting) => setting.demand.id === parseInt(demandId, 10),
    );
    if (demandAccountSetting) {
      return demandAccountSetting.description !== ""
        ? demandAccountSetting.description
        : "契約時に認証情報を入力済みのため、「保存する」をクリックすると登録が完了します。";
    }
    return "";
  };

  const handleClose = () => {
    handleModalDataReset();
    setSelectedDemand(null);
    onClose();
  };

  const submit = (data: CreateDemandForm) => {
    const columns: { name: string; value: string }[] = [];
    Object.keys(data).map((key) => {
      if (key !== "demand_id") {
        columns.push({
          name: key,
          value: data[key],
        });
      }
    });

    if (onSubmit) {
      onSubmit({
        demand_id: parseInt(data.demand_id, 10),
        form_columns: columns,
      });
    }
    handleModalDataReset();
  };

  return (
    <ConfirmModal
      title="デマンドの新規登録"
      confirmText="保存する"
      cancelText="キャンセル"
      buttonColor="primary"
      disabled={!selectedDemand}
      loading={loading}
      overflowYScroll={!!selectedDemand}
      isOpen={isOpen}
      onClose={handleClose}
      onSubmit={handleSubmit(submit)}
    >
      <Styled.FormContainer>
        <Styled.FormGroup>
          <Styled.FormGroupLeft>
            <Spacer pr={5}>デマンド選択</Spacer>
          </Styled.FormGroupLeft>
          <Styled.FormGroupRight>
            <Select
              name="demand_id"
              value={selectedDemand}
              options={demandForms.map((form) => ({
                value: String(form.demand.id),
                label: form.demand.name,
              }))}
              onChange={handleChange}
            />
          </Styled.FormGroupRight>
        </Styled.FormGroup>
        {watch("demand_id") && (
          <Styled.DiscriptionBackground>
            <Typography color={colors.basic[800]} size="sm">
              {getDemandDescriptionById(watch("demand_id"))}
            </Typography>
          </Styled.DiscriptionBackground>
        )}

        {getDemandFormsById(watch("demand_id")).map((form) => (
          <Styled.FormGroup key={form.name}>
            <Styled.FormGroupLeft>
              <Spacer pr={5}>{form.label}</Spacer>
            </Styled.FormGroupLeft>
            <Styled.FormGroupRight>
              <TextField
                {...registerIui(
                  register(`${form.name}` as const, { required: true }),
                )}
                type={form.is_secure ? "password" : "text"}
                errorText={errors[form.name] ? "入力されていません" : ""}
              />
            </Styled.FormGroupRight>
          </Styled.FormGroup>
        ))}
      </Styled.FormContainer>
    </ConfirmModal>
  );
};

export { CreateModal };
