import { Domain } from "api-types";

import { RootState } from "../../../reducer";

export const getUserRole = (state: RootState): Domain.UserRole => {
  const currentCompany = state.user.currentCompany;
  if (currentCompany == null) {
    throw new Error("企業が選択されていません");
  }
  const { systemRole } = state.auth;
  if (systemRole === "admin") {
    return "admin";
  }
  return currentCompany.user_company_role.name;
};
