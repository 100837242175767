import { Domain } from "api-types";
import { Dispatch } from "redux";

import { AnalyticsFeaturePublishType } from "../../../api-client";
import { Company } from "../domain/company";
import { User } from "../domain/user";
import { initializeUser } from "../store/modules/user/actions/InitializeMeAction";
import { RootState } from "../store/reducer";
import { GetState } from "../store/type";

export enum AuthorityConstraint {
  ANYONE, // 全てのユーザ対象
  AUTHED, // 認証・認可済み
  AUTHED_DEMAND_SETTING_ALLOWED, // 認証・認可済み かつ デマンド設定が許可されている
  AUTHED_ANALYTICS_FEATURE_ALLOWED, // 分析機能が許可されている
}

export function isAuthorizationRequired(
  constraint: AuthorityConstraint,
): boolean {
  switch (constraint) {
    case AuthorityConstraint.AUTHED:
    case AuthorityConstraint.AUTHED_DEMAND_SETTING_ALLOWED:
      return true;
    default:
      return false;
  }
}

export function hasAuthorityRequirements(
  constraint: AuthorityConstraint,
  user: User,
  company: Company,
): boolean {
  switch (constraint) {
    case AuthorityConstraint.ANYONE:
    case AuthorityConstraint.AUTHED:
      return true;
    case AuthorityConstraint.AUTHED_DEMAND_SETTING_ALLOWED:
      if (user.user_system_role.name === "admin") {
        return true;
      }
      if (company.demand_account_setting_limit === null) {
        return true;
      } else if (company.demand_account_setting_limit.limit > 0) {
        return true;
      } else {
        return false;
      }
    case AuthorityConstraint.AUTHED_ANALYTICS_FEATURE_ALLOWED:
      if (
        company.analytics_feature_publish_type ===
        AnalyticsFeaturePublishType.Published
      ) {
        return true;
      } else if (
        company.analytics_feature_publish_type ===
          AnalyticsFeaturePublishType.OnlyAdmin &&
        user.user_system_role.name === "admin"
      ) {
        return true;
      } else {
        return false;
      }
    default:
      return false;
  }
}

// FIXME: 良い名前思いついたら変えてくだせぇ
export enum FuncAuthorityConstraint {
  DA_YIELD,
}

export function hasAuthorityRequirementsByUserRole(
  constraint: FuncAuthorityConstraint,
  role: Domain.UserRole,
): boolean {
  switch (constraint) {
    case FuncAuthorityConstraint.DA_YIELD:
      return role === "admin";
    default:
      return false;
  }
}

export async function getUserAndSyncStoreState(
  getState: GetState,
  dispatch: Dispatch,
): Promise<RootState> {
  let state = getState();
  if (!state.user.me.data) {
    await dispatch(initializeUser());
    state = getState();
  }
  return state;
}
