/* tslint:disable */
/* eslint-disable */
/**
 * DataStrap API
 * DataStrapの内部向けAPI
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BidStrapReportSearch } from '../model';
// @ts-ignore
import { Category } from '../model';
// @ts-ignore
import { CategoryUpdateRequest } from '../model';
// @ts-ignore
import { CustomReportRequestFromDashboard } from '../model';
// @ts-ignore
import { CustomReportResult } from '../model';
// @ts-ignore
import { CustomReportSearch } from '../model';
// @ts-ignore
import { DashboardDemandReportStruct } from '../model';
// @ts-ignore
import { DashboardForecast } from '../model';
// @ts-ignore
import { DashboardForecastFilter } from '../model';
// @ts-ignore
import { DashboardItem } from '../model';
// @ts-ignore
import { DashboardItemCreateRequest } from '../model';
// @ts-ignore
import { DashboardItemListResponse } from '../model';
// @ts-ignore
import { DashboardItemUpdateRequest } from '../model';
// @ts-ignore
import { Demand } from '../model';
// @ts-ignore
import { DemandAccount } from '../model';
// @ts-ignore
import { DemandAccountCreative } from '../model';
// @ts-ignore
import { DemandAccountCreativeChannelType } from '../model';
// @ts-ignore
import { DemandAccountCreativeDetail } from '../model';
// @ts-ignore
import { DemandAccountCreativeDeviceType } from '../model';
// @ts-ignore
import { DemandAccountCreativeIds } from '../model';
// @ts-ignore
import { DemandAccountCreativeOsType } from '../model';
// @ts-ignore
import { DemandAccountCreativeRelationTo } from '../model';
// @ts-ignore
import { DemandAccountCreativeStatus } from '../model';
// @ts-ignore
import { DemandAccountCredencial } from '../model';
// @ts-ignore
import { DemandAccountDetail } from '../model';
// @ts-ignore
import { DemandAccountReportStatus } from '../model';
// @ts-ignore
import { DemandAccountUpdateInfo } from '../model';
// @ts-ignore
import { DemandAccountWithScraperStatus } from '../model';
// @ts-ignore
import { DemandFormConfig } from '../model';
// @ts-ignore
import { DemandReportSearchStruct } from '../model';
// @ts-ignore
import { GamLineItemCreativeDetailRelation } from '../model';
// @ts-ignore
import { GamLineItemDetail } from '../model';
// @ts-ignore
import { GamLineItemYieldSetting } from '../model';
// @ts-ignore
import { PostSavedCustomReport } from '../model';
// @ts-ignore
import { RpzApiViewsBidStrapGamAdUnits200Response } from '../model';
// @ts-ignore
import { RpzApiViewsBidStrapReportsFetch200Response } from '../model';
// @ts-ignore
import { RpzApiViewsBidStrapSites200Response } from '../model';
// @ts-ignore
import { RpzApiViewsCategoryCreate201Response } from '../model';
// @ts-ignore
import { RpzApiViewsCategoryCreateRequest } from '../model';
// @ts-ignore
import { RpzApiViewsDemandAccountCreate201Response } from '../model';
// @ts-ignore
import { RpzApiViewsDemandAccountUpdate200Response } from '../model';
// @ts-ignore
import { SavedCustomReport } from '../model';
// @ts-ignore
import { SavedCustomReportDetail } from '../model';
// @ts-ignore
import { SiteCreativeRequest } from '../model';
// @ts-ignore
import { UserStruct } from '../model';
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 分析機能ダッシュボードの埋め込みURLを取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsAnalyticsFeatureGetDashboard: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/analytics_feature/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * BIDSTRAPレポートに紐づくGAM広告ユニット一覧を取得する
         * @summary BIDSTRAPレポートに紐づくGAM広告ユニット一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsBidStrapGamAdUnits: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bid_strap/gam_ad_units`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * BIDSTRAPレポートをCSVで取得するエンドポイント
         * @summary BIDSTRAPレポートのCSVダウンロード
         * @param {BidStrapReportSearch} [bidStrapReportSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsBidStrapReportsDownload: async (bidStrapReportSearch?: BidStrapReportSearch, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bid_strap_reports/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = bidStrapReportSearch;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * BIDSTRAPレポートを取得するエンドポイント
         * @summary BIDSTRAPレポート
         * @param {BidStrapReportSearch} [bidStrapReportSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsBidStrapReportsFetch: async (bidStrapReportSearch?: BidStrapReportSearch, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bid_strap_reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = bidStrapReportSearch;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * BIDSTRAPレポートに紐づくサイト一覧を取得する
         * @summary BIDSTRAPレポートに紐づくサイト一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsBidStrapSites: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bid_strap/sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary カテゴリ作成
         * @param {RpzApiViewsCategoryCreateRequest} [rpzApiViewsCategoryCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCategoryCreate: async (rpzApiViewsCategoryCreateRequest?: RpzApiViewsCategoryCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = rpzApiViewsCategoryCreateRequest;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary カテゴリの削除
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCategoryDelete: async (categoryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('rpzApiViewsCategoryDelete', 'categoryId', categoryId)
            const localVarPath = `/v1/categories/{category_id}`
                .replace(`{${"category_id"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary カテゴリクリエイティブの削除
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCategoryDeleteCategoryCreative: async (demandAccountCreativeIds: DemandAccountCreativeIds, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'demandAccountCreativeIds' is not null or undefined
            assertParamExists('rpzApiViewsCategoryDeleteCategoryCreative', 'demandAccountCreativeIds', demandAccountCreativeIds)
            const localVarPath = `/v1/category_creatives`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = demandAccountCreativeIds;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary カテゴリクリエイティブ2の削除
         * @param {number} xCompanyId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCategoryDeleteCategoryCreative2: async (xCompanyId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsCategoryDeleteCategoryCreative2', 'xCompanyId', xCompanyId)
            // verify required parameter 'demandAccountCreativeIds' is not null or undefined
            assertParamExists('rpzApiViewsCategoryDeleteCategoryCreative2', 'demandAccountCreativeIds', demandAccountCreativeIds)
            const localVarPath = `/v1/category_creatives2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = demandAccountCreativeIds;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary カテゴリ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCategoryList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary カテゴリの更新
         * @param {number} categoryId 
         * @param {CategoryUpdateRequest} categoryUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCategoryUpdate: async (categoryId: number, categoryUpdateRequest: CategoryUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('rpzApiViewsCategoryUpdate', 'categoryId', categoryId)
            // verify required parameter 'categoryUpdateRequest' is not null or undefined
            assertParamExists('rpzApiViewsCategoryUpdate', 'categoryUpdateRequest', categoryUpdateRequest)
            const localVarPath = `/v1/categories/{category_id}`
                .replace(`{${"category_id"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = categoryUpdateRequest;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary カテゴリクリエイティブの更新
         * @param {number} categoryId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCategoryUpdateCategoryCreative: async (categoryId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('rpzApiViewsCategoryUpdateCategoryCreative', 'categoryId', categoryId)
            // verify required parameter 'demandAccountCreativeIds' is not null or undefined
            assertParamExists('rpzApiViewsCategoryUpdateCategoryCreative', 'demandAccountCreativeIds', demandAccountCreativeIds)
            const localVarPath = `/v1/categories/{category_id}/category_creatives`
                .replace(`{${"category_id"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = demandAccountCreativeIds;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary カテゴリクリエイティブ2の更新
         * @param {number} xCompanyId 
         * @param {number} categoryId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCategoryUpdateCategoryCreative2: async (xCompanyId: number, categoryId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsCategoryUpdateCategoryCreative2', 'xCompanyId', xCompanyId)
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('rpzApiViewsCategoryUpdateCategoryCreative2', 'categoryId', categoryId)
            // verify required parameter 'demandAccountCreativeIds' is not null or undefined
            assertParamExists('rpzApiViewsCategoryUpdateCategoryCreative2', 'demandAccountCreativeIds', demandAccountCreativeIds)
            const localVarPath = `/v1/categories/{category_id}/category_creatives2`
                .replace(`{${"category_id"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = demandAccountCreativeIds;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CSVインポート可能なデマンドに属するデマンドアカウントの一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCsvImportGetDemandAccounts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/csv_import/demand_accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CSV ファイルを渡してデマンドレポートを取り込む
         * @param {number} [demandAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCsvImportPost: async (demandAccountId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/csv_import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (demandAccountId !== undefined) { 
                localVarFormParams.append('demand_account_id', demandAccountId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 詳細レポートをCSVで取得するエンドポイント
         * @summary 詳細レポートのCSVダウンロード
         * @param {number} xCompanyId 
         * @param {CustomReportSearch} [customReportSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCustomReportsDownload: async (xCompanyId: number, customReportSearch?: CustomReportSearch, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsCustomReportsDownload', 'xCompanyId', xCompanyId)
            const localVarPath = `/v1/custom_reports/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = customReportSearch;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタムレポート保存クエリを作成します
         * @summary カスタムレポート保存クエリの作成
         * @param {number} xCompanyId 
         * @param {PostSavedCustomReport} postSavedCustomReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCustomReportsSavedQueryCreate: async (xCompanyId: number, postSavedCustomReport: PostSavedCustomReport, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsCustomReportsSavedQueryCreate', 'xCompanyId', xCompanyId)
            // verify required parameter 'postSavedCustomReport' is not null or undefined
            assertParamExists('rpzApiViewsCustomReportsSavedQueryCreate', 'postSavedCustomReport', postSavedCustomReport)
            const localVarPath = `/v1/custom_reports/saved_query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = postSavedCustomReport;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタムレポート保存クエリを削除します
         * @summary カスタムレポート保存クエリ削除
         * @param {number} xCompanyId 
         * @param {number} queryId 削除対象のクエリID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCustomReportsSavedQueryDelete: async (xCompanyId: number, queryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsCustomReportsSavedQueryDelete', 'xCompanyId', xCompanyId)
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('rpzApiViewsCustomReportsSavedQueryDelete', 'queryId', queryId)
            const localVarPath = `/v1/custom_reports/saved_query/{query_id}`
                .replace(`{${"query_id"}}`, encodeURIComponent(String(queryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary テーブル・グラフ表示用のデータ取得。Highcharts用に整形したデータを返す
         * @param {number} queryId 
         * @param {CustomReportRequestFromDashboard} [customReportRequestFromDashboard] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCustomReportsSavedQueryFetchDataHighcharts: async (queryId: number, customReportRequestFromDashboard?: CustomReportRequestFromDashboard, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('rpzApiViewsCustomReportsSavedQueryFetchDataHighcharts', 'queryId', queryId)
            const localVarPath = `/v1/custom_reports/saved_query/{query_id}/data/highcharts`
                .replace(`{${"query_id"}}`, encodeURIComponent(String(queryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = customReportRequestFromDashboard;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタムレポート保存クエリの一覧を返します
         * @summary カスタムレポート保存クエリ一覧
         * @param {number} xCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCustomReportsSavedQueryList: async (xCompanyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsCustomReportsSavedQueryList', 'xCompanyId', xCompanyId)
            const localVarPath = `/v1/custom_reports/saved_query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタムレポート保存クエリの詳細(dimensionやindicator,filterなど)を返します
         * @summary カスタムレポート保存クエリ詳細
         * @param {number} xCompanyId 
         * @param {number} queryId 表示対象のクエリID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCustomReportsSavedQueryShow: async (xCompanyId: number, queryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsCustomReportsSavedQueryShow', 'xCompanyId', xCompanyId)
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('rpzApiViewsCustomReportsSavedQueryShow', 'queryId', queryId)
            const localVarPath = `/v1/custom_reports/saved_query/{query_id}`
                .replace(`{${"query_id"}}`, encodeURIComponent(String(queryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタムレポート保存クエリを更新します
         * @summary カスタムレポート保存クエリ更新
         * @param {number} xCompanyId 
         * @param {number} queryId 更新対象のクエリID
         * @param {PostSavedCustomReport} [postSavedCustomReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCustomReportsSavedQueryUpdate: async (xCompanyId: number, queryId: number, postSavedCustomReport?: PostSavedCustomReport, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsCustomReportsSavedQueryUpdate', 'xCompanyId', xCompanyId)
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('rpzApiViewsCustomReportsSavedQueryUpdate', 'queryId', queryId)
            const localVarPath = `/v1/custom_reports/saved_query/{query_id}`
                .replace(`{${"query_id"}}`, encodeURIComponent(String(queryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = postSavedCustomReport;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 詳細レポートを取得するエンドポイント
         * @summary 詳細レポート
         * @param {number} xCompanyId 
         * @param {CustomReportSearch} [customReportSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCustomReportsSearch: async (xCompanyId: number, customReportSearch?: CustomReportSearch, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsCustomReportsSearch', 'xCompanyId', xCompanyId)
            const localVarPath = `/v1/custom_reports/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = customReportSearch;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * フィルターを指定すると着予計算に使うデータが絞り込まれる
         * @summary 着地予想を返す
         * @param {DashboardForecastFilter} [dashboardForecastFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDashboardForecastShow: async (dashboardForecastFilter?: DashboardForecastFilter, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dashboard/forecast`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = dashboardForecastFilter;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アイテム追加
         * @param {DashboardItemCreateRequest} [dashboardItemCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDashboardItemCreate: async (dashboardItemCreateRequest?: DashboardItemCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dashboard/item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = dashboardItemCreateRequest;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アイテム削除
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDashboardItemDelete: async (itemId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('rpzApiViewsDashboardItemDelete', 'itemId', itemId)
            const localVarPath = `/v1/dashboard/item/{item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アイテム更新
         * @param {number} itemId 
         * @param {DashboardItemUpdateRequest} [dashboardItemUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDashboardItemUpdate: async (itemId: number, dashboardItemUpdateRequest?: DashboardItemUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('rpzApiViewsDashboardItemUpdate', 'itemId', itemId)
            const localVarPath = `/v1/dashboard/item/{item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = dashboardItemUpdateRequest;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ダッシュボード取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDashboardShow: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 新規デマンドアカウント作成
         * @param {DemandAccountCredencial} [demandAccountCredencial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountCreate: async (demandAccountCredencial?: DemandAccountCredencial, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/demand_accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = demandAccountCredencial;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DemandAccountCreativeの論理復活
         * @param {number} xCompanyId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountCreativeActivate: async (xCompanyId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsDemandAccountCreativeActivate', 'xCompanyId', xCompanyId)
            // verify required parameter 'demandAccountCreativeIds' is not null or undefined
            assertParamExists('rpzApiViewsDemandAccountCreativeActivate', 'demandAccountCreativeIds', demandAccountCreativeIds)
            const localVarPath = `/v1/demand_account_creatives/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = demandAccountCreativeIds;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DemandAccountCreativeの論理削除
         * @param {number} xCompanyId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountCreativeDelete: async (xCompanyId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsDemandAccountCreativeDelete', 'xCompanyId', xCompanyId)
            // verify required parameter 'demandAccountCreativeIds' is not null or undefined
            assertParamExists('rpzApiViewsDemandAccountCreativeDelete', 'demandAccountCreativeIds', demandAccountCreativeIds)
            const localVarPath = `/v1/demand_account_creatives`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = demandAccountCreativeIds;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DemandAccountCreative一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountCreativeList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/demand_account_creatives`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 検索条件に合致したデマンドアカウントクリエイティブ一覧取得
         * @param {number} xCompanyId 
         * @param {DemandAccountCreativeRelationTo} relationTo 
         * @param {DemandAccountCreativeStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountCreativeRelationSearch: async (xCompanyId: number, relationTo: DemandAccountCreativeRelationTo, status: DemandAccountCreativeStatus, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsDemandAccountCreativeRelationSearch', 'xCompanyId', xCompanyId)
            // verify required parameter 'relationTo' is not null or undefined
            assertParamExists('rpzApiViewsDemandAccountCreativeRelationSearch', 'relationTo', relationTo)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('rpzApiViewsDemandAccountCreativeRelationSearch', 'status', status)
            const localVarPath = `/v1/demand_account_creatives/relation_search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (relationTo !== undefined) {
                localVarQueryParameter['relation_to'] = relationTo;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DemandAccountCreativeのChannelTypeを変更する
         * @param {number} xCompanyId 
         * @param {DemandAccountCreativeChannelType} [demandAccountCreativeChannelType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountCreativeUpdateChannelType: async (xCompanyId: number, demandAccountCreativeChannelType?: DemandAccountCreativeChannelType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsDemandAccountCreativeUpdateChannelType', 'xCompanyId', xCompanyId)
            const localVarPath = `/v1/demand_account_creatives/channel_type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = demandAccountCreativeChannelType;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DemandAccountCreativeのDeviceTypeを変更する
         * @param {DemandAccountCreativeDeviceType} [demandAccountCreativeDeviceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountCreativeUpdateDeviceType: async (demandAccountCreativeDeviceType?: DemandAccountCreativeDeviceType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/demand_account_creatives/device_type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = demandAccountCreativeDeviceType;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DemandAccountCreativeのOSを変更する
         * @param {DemandAccountCreativeOsType} [demandAccountCreativeOsType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountCreativeUpdateOsType: async (demandAccountCreativeOsType?: DemandAccountCreativeOsType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/demand_account_creatives/os_type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = demandAccountCreativeOsType;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary デマンドアカウントの削除
         * @param {number} demandAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountDelete: async (demandAccountId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'demandAccountId' is not null or undefined
            assertParamExists('rpzApiViewsDemandAccountDelete', 'demandAccountId', demandAccountId)
            const localVarPath = `/v1/demand_accounts/{demand_account_id}`
                .replace(`{${"demand_account_id"}}`, encodeURIComponent(String(demandAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary デマンド設定画面で表示するデマンドアカウント一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountGetList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/demand_accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary デマンド登録・編集の際に選択可能なデマンドアカウント一覧
         * @param {number} xCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountGetSettingList: async (xCompanyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsDemandAccountGetSettingList', 'xCompanyId', xCompanyId)
            const localVarPath = `/v1/demand_accounts/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary デマンド設定画面で編集ボタンを押した時のデマンドアカウント情報
         * @param {number} xCompanyId 
         * @param {number} demandAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountShowDetail: async (xCompanyId: number, demandAccountId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsDemandAccountShowDetail', 'xCompanyId', xCompanyId)
            // verify required parameter 'demandAccountId' is not null or undefined
            assertParamExists('rpzApiViewsDemandAccountShowDetail', 'demandAccountId', demandAccountId)
            const localVarPath = `/v1/demand_accounts/{demand_account_id}`
                .replace(`{${"demand_account_id"}}`, encodeURIComponent(String(demandAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary デマンドアカウント情報の更新
         * @param {number} demandAccountId 
         * @param {DemandAccountUpdateInfo} [demandAccountUpdateInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountUpdate: async (demandAccountId: number, demandAccountUpdateInfo?: DemandAccountUpdateInfo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'demandAccountId' is not null or undefined
            assertParamExists('rpzApiViewsDemandAccountUpdate', 'demandAccountId', demandAccountId)
            const localVarPath = `/v1/demand_accounts/{demand_account_id}`
                .replace(`{${"demand_account_id"}}`, encodeURIComponent(String(demandAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = demandAccountUpdateInfo;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 媒体社に紐づくデマンド一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/demands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GAM枠とデマンド枠のマッピング(gam_demand_creativeの作成)
         * @param {number} xCompanyId 
         * @param {number} gamLineItemCreativeId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsGamCreateGamDemandCreative: async (xCompanyId: number, gamLineItemCreativeId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsGamCreateGamDemandCreative', 'xCompanyId', xCompanyId)
            // verify required parameter 'gamLineItemCreativeId' is not null or undefined
            assertParamExists('rpzApiViewsGamCreateGamDemandCreative', 'gamLineItemCreativeId', gamLineItemCreativeId)
            // verify required parameter 'demandAccountCreativeIds' is not null or undefined
            assertParamExists('rpzApiViewsGamCreateGamDemandCreative', 'demandAccountCreativeIds', demandAccountCreativeIds)
            const localVarPath = `/v1/gam_line_item_creatives/{gam_line_item_creative_id}/demand_account_creatives`
                .replace(`{${"gam_line_item_creative_id"}}`, encodeURIComponent(String(gamLineItemCreativeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = demandAccountCreativeIds;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GAM枠とデマンド枠のマッピング解除(gam_demand_creativeの削除)
         * @param {number} xCompanyId 
         * @param {number} gamLineItemCreativeId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsGamDeleteGamDemandCreative: async (xCompanyId: number, gamLineItemCreativeId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsGamDeleteGamDemandCreative', 'xCompanyId', xCompanyId)
            // verify required parameter 'gamLineItemCreativeId' is not null or undefined
            assertParamExists('rpzApiViewsGamDeleteGamDemandCreative', 'gamLineItemCreativeId', gamLineItemCreativeId)
            // verify required parameter 'demandAccountCreativeIds' is not null or undefined
            assertParamExists('rpzApiViewsGamDeleteGamDemandCreative', 'demandAccountCreativeIds', demandAccountCreativeIds)
            const localVarPath = `/v1/gam_line_item_creatives/{gam_line_item_creative_id}/demand_account_creatives`
                .replace(`{${"gam_line_item_creative_id"}}`, encodeURIComponent(String(gamLineItemCreativeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = demandAccountCreativeIds;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GAM申込情報に紐づくクリエイティブ一覧を取得
         * @param {number} xCompanyId 
         * @param {number} gamLineItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsGamListGamLineItemCreatives: async (xCompanyId: number, gamLineItemId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsGamListGamLineItemCreatives', 'xCompanyId', xCompanyId)
            // verify required parameter 'gamLineItemId' is not null or undefined
            assertParamExists('rpzApiViewsGamListGamLineItemCreatives', 'gamLineItemId', gamLineItemId)
            const localVarPath = `/v1/gam_line_item_creatives`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gamLineItemId !== undefined) {
                localVarQueryParameter['gam_line_item_id'] = gamLineItemId;
            }

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary イールド設定の更新
         * @param {Array<GamLineItemYieldSetting>} gamLineItemYieldSetting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsGamYieldUpdate: async (gamLineItemYieldSetting: Array<GamLineItemYieldSetting>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gamLineItemYieldSetting' is not null or undefined
            assertParamExists('rpzApiViewsGamYieldUpdate', 'gamLineItemYieldSetting', gamLineItemYieldSetting)
            const localVarPath = `/v1/gam_line_item_yield_settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = gamLineItemYieldSetting;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary デフォルトダッシュボード数値の取得
         * @param {DemandReportSearchStruct} [demandReportSearchStruct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsReportSearch: async (demandReportSearchStruct?: DemandReportSearchStruct, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/demand_reports/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = demandReportSearchStruct;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary カテゴリ一覧
         * @param {number} xCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsReportStatusList: async (xCompanyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsReportStatusList', 'xCompanyId', xCompanyId)
            const localVarPath = `/v1/report_status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary サイトクリエイティブの削除
         * @param {number} xCompanyId 
         * @param {SiteCreativeRequest} siteCreativeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsSiteDeleteSiteCreative: async (xCompanyId: number, siteCreativeRequest: SiteCreativeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsSiteDeleteSiteCreative', 'xCompanyId', xCompanyId)
            // verify required parameter 'siteCreativeRequest' is not null or undefined
            assertParamExists('rpzApiViewsSiteDeleteSiteCreative', 'siteCreativeRequest', siteCreativeRequest)
            const localVarPath = `/v1/site_creatives`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = siteCreativeRequest;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary サイトクリエイティブの更新
         * @param {number} xCompanyId 
         * @param {number} siteId 
         * @param {SiteCreativeRequest} [siteCreativeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsSiteUpsertSiteCreative: async (xCompanyId: number, siteId: number, siteCreativeRequest?: SiteCreativeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xCompanyId' is not null or undefined
            assertParamExists('rpzApiViewsSiteUpsertSiteCreative', 'xCompanyId', xCompanyId)
            // verify required parameter 'siteId' is not null or undefined
            assertParamExists('rpzApiViewsSiteUpsertSiteCreative', 'siteId', siteId)
            const localVarPath = `/v1/sites/{site_id}/site_creatives`
                .replace(`{${"site_id"}}`, encodeURIComponent(String(siteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xCompanyId !== undefined && xCompanyId !== null) {
                localVarHeaderParameter['X-Company-Id'] = String(JSON.stringify(xCompanyId));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = siteCreativeRequest;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ログインしているアカウントに関する情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsUserGetMe: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 分析機能ダッシュボードの埋め込みURLを取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsAnalyticsFeatureGetDashboard(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsAnalyticsFeatureGetDashboard(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * BIDSTRAPレポートに紐づくGAM広告ユニット一覧を取得する
         * @summary BIDSTRAPレポートに紐づくGAM広告ユニット一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsBidStrapGamAdUnits(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RpzApiViewsBidStrapGamAdUnits200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsBidStrapGamAdUnits(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * BIDSTRAPレポートをCSVで取得するエンドポイント
         * @summary BIDSTRAPレポートのCSVダウンロード
         * @param {BidStrapReportSearch} [bidStrapReportSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsBidStrapReportsDownload(bidStrapReportSearch?: BidStrapReportSearch, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsBidStrapReportsDownload(bidStrapReportSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * BIDSTRAPレポートを取得するエンドポイント
         * @summary BIDSTRAPレポート
         * @param {BidStrapReportSearch} [bidStrapReportSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsBidStrapReportsFetch(bidStrapReportSearch?: BidStrapReportSearch, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RpzApiViewsBidStrapReportsFetch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsBidStrapReportsFetch(bidStrapReportSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * BIDSTRAPレポートに紐づくサイト一覧を取得する
         * @summary BIDSTRAPレポートに紐づくサイト一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsBidStrapSites(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RpzApiViewsBidStrapSites200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsBidStrapSites(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary カテゴリ作成
         * @param {RpzApiViewsCategoryCreateRequest} [rpzApiViewsCategoryCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsCategoryCreate(rpzApiViewsCategoryCreateRequest?: RpzApiViewsCategoryCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RpzApiViewsCategoryCreate201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsCategoryCreate(rpzApiViewsCategoryCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary カテゴリの削除
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsCategoryDelete(categoryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsCategoryDelete(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary カテゴリクリエイティブの削除
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsCategoryDeleteCategoryCreative(demandAccountCreativeIds: DemandAccountCreativeIds, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsCategoryDeleteCategoryCreative(demandAccountCreativeIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary カテゴリクリエイティブ2の削除
         * @param {number} xCompanyId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsCategoryDeleteCategoryCreative2(xCompanyId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsCategoryDeleteCategoryCreative2(xCompanyId, demandAccountCreativeIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary カテゴリ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsCategoryList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Category>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsCategoryList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary カテゴリの更新
         * @param {number} categoryId 
         * @param {CategoryUpdateRequest} categoryUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsCategoryUpdate(categoryId: number, categoryUpdateRequest: CategoryUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RpzApiViewsCategoryCreate201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsCategoryUpdate(categoryId, categoryUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary カテゴリクリエイティブの更新
         * @param {number} categoryId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsCategoryUpdateCategoryCreative(categoryId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemandAccountCreativeDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsCategoryUpdateCategoryCreative(categoryId, demandAccountCreativeIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary カテゴリクリエイティブ2の更新
         * @param {number} xCompanyId 
         * @param {number} categoryId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsCategoryUpdateCategoryCreative2(xCompanyId: number, categoryId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemandAccountCreativeDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsCategoryUpdateCategoryCreative2(xCompanyId, categoryId, demandAccountCreativeIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary CSVインポート可能なデマンドに属するデマンドアカウントの一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsCsvImportGetDemandAccounts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemandAccount>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsCsvImportGetDemandAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary CSV ファイルを渡してデマンドレポートを取り込む
         * @param {number} [demandAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsCsvImportPost(demandAccountId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsCsvImportPost(demandAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 詳細レポートをCSVで取得するエンドポイント
         * @summary 詳細レポートのCSVダウンロード
         * @param {number} xCompanyId 
         * @param {CustomReportSearch} [customReportSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsCustomReportsDownload(xCompanyId: number, customReportSearch?: CustomReportSearch, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsCustomReportsDownload(xCompanyId, customReportSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カスタムレポート保存クエリを作成します
         * @summary カスタムレポート保存クエリの作成
         * @param {number} xCompanyId 
         * @param {PostSavedCustomReport} postSavedCustomReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsCustomReportsSavedQueryCreate(xCompanyId: number, postSavedCustomReport: PostSavedCustomReport, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SavedCustomReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsCustomReportsSavedQueryCreate(xCompanyId, postSavedCustomReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カスタムレポート保存クエリを削除します
         * @summary カスタムレポート保存クエリ削除
         * @param {number} xCompanyId 
         * @param {number} queryId 削除対象のクエリID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsCustomReportsSavedQueryDelete(xCompanyId: number, queryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsCustomReportsSavedQueryDelete(xCompanyId, queryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary テーブル・グラフ表示用のデータ取得。Highcharts用に整形したデータを返す
         * @param {number} queryId 
         * @param {CustomReportRequestFromDashboard} [customReportRequestFromDashboard] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsCustomReportsSavedQueryFetchDataHighcharts(queryId: number, customReportRequestFromDashboard?: CustomReportRequestFromDashboard, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomReportResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsCustomReportsSavedQueryFetchDataHighcharts(queryId, customReportRequestFromDashboard, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カスタムレポート保存クエリの一覧を返します
         * @summary カスタムレポート保存クエリ一覧
         * @param {number} xCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsCustomReportsSavedQueryList(xCompanyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SavedCustomReport>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsCustomReportsSavedQueryList(xCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カスタムレポート保存クエリの詳細(dimensionやindicator,filterなど)を返します
         * @summary カスタムレポート保存クエリ詳細
         * @param {number} xCompanyId 
         * @param {number} queryId 表示対象のクエリID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsCustomReportsSavedQueryShow(xCompanyId: number, queryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SavedCustomReportDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsCustomReportsSavedQueryShow(xCompanyId, queryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カスタムレポート保存クエリを更新します
         * @summary カスタムレポート保存クエリ更新
         * @param {number} xCompanyId 
         * @param {number} queryId 更新対象のクエリID
         * @param {PostSavedCustomReport} [postSavedCustomReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsCustomReportsSavedQueryUpdate(xCompanyId: number, queryId: number, postSavedCustomReport?: PostSavedCustomReport, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SavedCustomReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsCustomReportsSavedQueryUpdate(xCompanyId, queryId, postSavedCustomReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 詳細レポートを取得するエンドポイント
         * @summary 詳細レポート
         * @param {number} xCompanyId 
         * @param {CustomReportSearch} [customReportSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsCustomReportsSearch(xCompanyId: number, customReportSearch?: CustomReportSearch, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomReportResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsCustomReportsSearch(xCompanyId, customReportSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * フィルターを指定すると着予計算に使うデータが絞り込まれる
         * @summary 着地予想を返す
         * @param {DashboardForecastFilter} [dashboardForecastFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsDashboardForecastShow(dashboardForecastFilter?: DashboardForecastFilter, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardForecast>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsDashboardForecastShow(dashboardForecastFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary アイテム追加
         * @param {DashboardItemCreateRequest} [dashboardItemCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsDashboardItemCreate(dashboardItemCreateRequest?: DashboardItemCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsDashboardItemCreate(dashboardItemCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary アイテム削除
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsDashboardItemDelete(itemId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsDashboardItemDelete(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary アイテム更新
         * @param {number} itemId 
         * @param {DashboardItemUpdateRequest} [dashboardItemUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsDashboardItemUpdate(itemId: number, dashboardItemUpdateRequest?: DashboardItemUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsDashboardItemUpdate(itemId, dashboardItemUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ダッシュボード取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsDashboardShow(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardItemListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsDashboardShow(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 新規デマンドアカウント作成
         * @param {DemandAccountCredencial} [demandAccountCredencial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsDemandAccountCreate(demandAccountCredencial?: DemandAccountCredencial, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RpzApiViewsDemandAccountCreate201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsDemandAccountCreate(demandAccountCredencial, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary DemandAccountCreativeの論理復活
         * @param {number} xCompanyId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsDemandAccountCreativeActivate(xCompanyId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemandAccountCreativeDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsDemandAccountCreativeActivate(xCompanyId, demandAccountCreativeIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary DemandAccountCreativeの論理削除
         * @param {number} xCompanyId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsDemandAccountCreativeDelete(xCompanyId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemandAccountCreativeDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsDemandAccountCreativeDelete(xCompanyId, demandAccountCreativeIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary DemandAccountCreative一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsDemandAccountCreativeList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemandAccountCreativeDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsDemandAccountCreativeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 検索条件に合致したデマンドアカウントクリエイティブ一覧取得
         * @param {number} xCompanyId 
         * @param {DemandAccountCreativeRelationTo} relationTo 
         * @param {DemandAccountCreativeStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsDemandAccountCreativeRelationSearch(xCompanyId: number, relationTo: DemandAccountCreativeRelationTo, status: DemandAccountCreativeStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemandAccountCreative>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsDemandAccountCreativeRelationSearch(xCompanyId, relationTo, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary DemandAccountCreativeのChannelTypeを変更する
         * @param {number} xCompanyId 
         * @param {DemandAccountCreativeChannelType} [demandAccountCreativeChannelType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsDemandAccountCreativeUpdateChannelType(xCompanyId: number, demandAccountCreativeChannelType?: DemandAccountCreativeChannelType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemandAccountCreativeDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsDemandAccountCreativeUpdateChannelType(xCompanyId, demandAccountCreativeChannelType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary DemandAccountCreativeのDeviceTypeを変更する
         * @param {DemandAccountCreativeDeviceType} [demandAccountCreativeDeviceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsDemandAccountCreativeUpdateDeviceType(demandAccountCreativeDeviceType?: DemandAccountCreativeDeviceType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemandAccountCreativeDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsDemandAccountCreativeUpdateDeviceType(demandAccountCreativeDeviceType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary DemandAccountCreativeのOSを変更する
         * @param {DemandAccountCreativeOsType} [demandAccountCreativeOsType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsDemandAccountCreativeUpdateOsType(demandAccountCreativeOsType?: DemandAccountCreativeOsType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemandAccountCreative>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsDemandAccountCreativeUpdateOsType(demandAccountCreativeOsType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary デマンドアカウントの削除
         * @param {number} demandAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsDemandAccountDelete(demandAccountId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsDemandAccountDelete(demandAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary デマンド設定画面で表示するデマンドアカウント一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsDemandAccountGetList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemandAccountWithScraperStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsDemandAccountGetList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary デマンド登録・編集の際に選択可能なデマンドアカウント一覧
         * @param {number} xCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsDemandAccountGetSettingList(xCompanyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemandFormConfig>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsDemandAccountGetSettingList(xCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary デマンド設定画面で編集ボタンを押した時のデマンドアカウント情報
         * @param {number} xCompanyId 
         * @param {number} demandAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsDemandAccountShowDetail(xCompanyId: number, demandAccountId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemandAccountDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsDemandAccountShowDetail(xCompanyId, demandAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary デマンドアカウント情報の更新
         * @param {number} demandAccountId 
         * @param {DemandAccountUpdateInfo} [demandAccountUpdateInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsDemandAccountUpdate(demandAccountId: number, demandAccountUpdateInfo?: DemandAccountUpdateInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RpzApiViewsDemandAccountUpdate200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsDemandAccountUpdate(demandAccountId, demandAccountUpdateInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 媒体社に紐づくデマンド一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsDemandList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Demand>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsDemandList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GAM枠とデマンド枠のマッピング(gam_demand_creativeの作成)
         * @param {number} xCompanyId 
         * @param {number} gamLineItemCreativeId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsGamCreateGamDemandCreative(xCompanyId: number, gamLineItemCreativeId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemandAccountCreative>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsGamCreateGamDemandCreative(xCompanyId, gamLineItemCreativeId, demandAccountCreativeIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GAM枠とデマンド枠のマッピング解除(gam_demand_creativeの削除)
         * @param {number} xCompanyId 
         * @param {number} gamLineItemCreativeId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsGamDeleteGamDemandCreative(xCompanyId: number, gamLineItemCreativeId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemandAccountCreative>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsGamDeleteGamDemandCreative(xCompanyId, gamLineItemCreativeId, demandAccountCreativeIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GAM申込情報に紐づくクリエイティブ一覧を取得
         * @param {number} xCompanyId 
         * @param {number} gamLineItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsGamListGamLineItemCreatives(xCompanyId: number, gamLineItemId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GamLineItemCreativeDetailRelation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsGamListGamLineItemCreatives(xCompanyId, gamLineItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary イールド設定の更新
         * @param {Array<GamLineItemYieldSetting>} gamLineItemYieldSetting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsGamYieldUpdate(gamLineItemYieldSetting: Array<GamLineItemYieldSetting>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GamLineItemDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsGamYieldUpdate(gamLineItemYieldSetting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary デフォルトダッシュボード数値の取得
         * @param {DemandReportSearchStruct} [demandReportSearchStruct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsReportSearch(demandReportSearchStruct?: DemandReportSearchStruct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DashboardDemandReportStruct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsReportSearch(demandReportSearchStruct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary カテゴリ一覧
         * @param {number} xCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsReportStatusList(xCompanyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemandAccountReportStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsReportStatusList(xCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary サイトクリエイティブの削除
         * @param {number} xCompanyId 
         * @param {SiteCreativeRequest} siteCreativeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsSiteDeleteSiteCreative(xCompanyId: number, siteCreativeRequest: SiteCreativeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsSiteDeleteSiteCreative(xCompanyId, siteCreativeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary サイトクリエイティブの更新
         * @param {number} xCompanyId 
         * @param {number} siteId 
         * @param {SiteCreativeRequest} [siteCreativeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsSiteUpsertSiteCreative(xCompanyId: number, siteId: number, siteCreativeRequest?: SiteCreativeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemandAccountCreativeDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsSiteUpsertSiteCreative(xCompanyId, siteId, siteCreativeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ログインしているアカウントに関する情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rpzApiViewsUserGetMe(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserStruct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rpzApiViewsUserGetMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary 分析機能ダッシュボードの埋め込みURLを取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsAnalyticsFeatureGetDashboard(options?: any): AxiosPromise<string> {
            return localVarFp.rpzApiViewsAnalyticsFeatureGetDashboard(options).then((request) => request(axios, basePath));
        },
        /**
         * BIDSTRAPレポートに紐づくGAM広告ユニット一覧を取得する
         * @summary BIDSTRAPレポートに紐づくGAM広告ユニット一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsBidStrapGamAdUnits(options?: any): AxiosPromise<RpzApiViewsBidStrapGamAdUnits200Response> {
            return localVarFp.rpzApiViewsBidStrapGamAdUnits(options).then((request) => request(axios, basePath));
        },
        /**
         * BIDSTRAPレポートをCSVで取得するエンドポイント
         * @summary BIDSTRAPレポートのCSVダウンロード
         * @param {BidStrapReportSearch} [bidStrapReportSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsBidStrapReportsDownload(bidStrapReportSearch?: BidStrapReportSearch, options?: any): AxiosPromise<string> {
            return localVarFp.rpzApiViewsBidStrapReportsDownload(bidStrapReportSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * BIDSTRAPレポートを取得するエンドポイント
         * @summary BIDSTRAPレポート
         * @param {BidStrapReportSearch} [bidStrapReportSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsBidStrapReportsFetch(bidStrapReportSearch?: BidStrapReportSearch, options?: any): AxiosPromise<RpzApiViewsBidStrapReportsFetch200Response> {
            return localVarFp.rpzApiViewsBidStrapReportsFetch(bidStrapReportSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * BIDSTRAPレポートに紐づくサイト一覧を取得する
         * @summary BIDSTRAPレポートに紐づくサイト一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsBidStrapSites(options?: any): AxiosPromise<RpzApiViewsBidStrapSites200Response> {
            return localVarFp.rpzApiViewsBidStrapSites(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary カテゴリ作成
         * @param {RpzApiViewsCategoryCreateRequest} [rpzApiViewsCategoryCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCategoryCreate(rpzApiViewsCategoryCreateRequest?: RpzApiViewsCategoryCreateRequest, options?: any): AxiosPromise<RpzApiViewsCategoryCreate201Response> {
            return localVarFp.rpzApiViewsCategoryCreate(rpzApiViewsCategoryCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary カテゴリの削除
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCategoryDelete(categoryId: number, options?: any): AxiosPromise<void> {
            return localVarFp.rpzApiViewsCategoryDelete(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary カテゴリクリエイティブの削除
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCategoryDeleteCategoryCreative(demandAccountCreativeIds: DemandAccountCreativeIds, options?: any): AxiosPromise<void> {
            return localVarFp.rpzApiViewsCategoryDeleteCategoryCreative(demandAccountCreativeIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary カテゴリクリエイティブ2の削除
         * @param {number} xCompanyId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCategoryDeleteCategoryCreative2(xCompanyId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any): AxiosPromise<void> {
            return localVarFp.rpzApiViewsCategoryDeleteCategoryCreative2(xCompanyId, demandAccountCreativeIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary カテゴリ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCategoryList(options?: any): AxiosPromise<Array<Category>> {
            return localVarFp.rpzApiViewsCategoryList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary カテゴリの更新
         * @param {number} categoryId 
         * @param {CategoryUpdateRequest} categoryUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCategoryUpdate(categoryId: number, categoryUpdateRequest: CategoryUpdateRequest, options?: any): AxiosPromise<RpzApiViewsCategoryCreate201Response> {
            return localVarFp.rpzApiViewsCategoryUpdate(categoryId, categoryUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary カテゴリクリエイティブの更新
         * @param {number} categoryId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCategoryUpdateCategoryCreative(categoryId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any): AxiosPromise<Array<DemandAccountCreativeDetail>> {
            return localVarFp.rpzApiViewsCategoryUpdateCategoryCreative(categoryId, demandAccountCreativeIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary カテゴリクリエイティブ2の更新
         * @param {number} xCompanyId 
         * @param {number} categoryId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCategoryUpdateCategoryCreative2(xCompanyId: number, categoryId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any): AxiosPromise<Array<DemandAccountCreativeDetail>> {
            return localVarFp.rpzApiViewsCategoryUpdateCategoryCreative2(xCompanyId, categoryId, demandAccountCreativeIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CSVインポート可能なデマンドに属するデマンドアカウントの一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCsvImportGetDemandAccounts(options?: any): AxiosPromise<Array<DemandAccount>> {
            return localVarFp.rpzApiViewsCsvImportGetDemandAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CSV ファイルを渡してデマンドレポートを取り込む
         * @param {number} [demandAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCsvImportPost(demandAccountId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.rpzApiViewsCsvImportPost(demandAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 詳細レポートをCSVで取得するエンドポイント
         * @summary 詳細レポートのCSVダウンロード
         * @param {number} xCompanyId 
         * @param {CustomReportSearch} [customReportSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCustomReportsDownload(xCompanyId: number, customReportSearch?: CustomReportSearch, options?: any): AxiosPromise<string> {
            return localVarFp.rpzApiViewsCustomReportsDownload(xCompanyId, customReportSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * カスタムレポート保存クエリを作成します
         * @summary カスタムレポート保存クエリの作成
         * @param {number} xCompanyId 
         * @param {PostSavedCustomReport} postSavedCustomReport 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCustomReportsSavedQueryCreate(xCompanyId: number, postSavedCustomReport: PostSavedCustomReport, options?: any): AxiosPromise<SavedCustomReport> {
            return localVarFp.rpzApiViewsCustomReportsSavedQueryCreate(xCompanyId, postSavedCustomReport, options).then((request) => request(axios, basePath));
        },
        /**
         * カスタムレポート保存クエリを削除します
         * @summary カスタムレポート保存クエリ削除
         * @param {number} xCompanyId 
         * @param {number} queryId 削除対象のクエリID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCustomReportsSavedQueryDelete(xCompanyId: number, queryId: number, options?: any): AxiosPromise<void> {
            return localVarFp.rpzApiViewsCustomReportsSavedQueryDelete(xCompanyId, queryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary テーブル・グラフ表示用のデータ取得。Highcharts用に整形したデータを返す
         * @param {number} queryId 
         * @param {CustomReportRequestFromDashboard} [customReportRequestFromDashboard] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCustomReportsSavedQueryFetchDataHighcharts(queryId: number, customReportRequestFromDashboard?: CustomReportRequestFromDashboard, options?: any): AxiosPromise<CustomReportResult> {
            return localVarFp.rpzApiViewsCustomReportsSavedQueryFetchDataHighcharts(queryId, customReportRequestFromDashboard, options).then((request) => request(axios, basePath));
        },
        /**
         * カスタムレポート保存クエリの一覧を返します
         * @summary カスタムレポート保存クエリ一覧
         * @param {number} xCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCustomReportsSavedQueryList(xCompanyId: number, options?: any): AxiosPromise<Array<SavedCustomReport>> {
            return localVarFp.rpzApiViewsCustomReportsSavedQueryList(xCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * カスタムレポート保存クエリの詳細(dimensionやindicator,filterなど)を返します
         * @summary カスタムレポート保存クエリ詳細
         * @param {number} xCompanyId 
         * @param {number} queryId 表示対象のクエリID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCustomReportsSavedQueryShow(xCompanyId: number, queryId: number, options?: any): AxiosPromise<SavedCustomReportDetail> {
            return localVarFp.rpzApiViewsCustomReportsSavedQueryShow(xCompanyId, queryId, options).then((request) => request(axios, basePath));
        },
        /**
         * カスタムレポート保存クエリを更新します
         * @summary カスタムレポート保存クエリ更新
         * @param {number} xCompanyId 
         * @param {number} queryId 更新対象のクエリID
         * @param {PostSavedCustomReport} [postSavedCustomReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCustomReportsSavedQueryUpdate(xCompanyId: number, queryId: number, postSavedCustomReport?: PostSavedCustomReport, options?: any): AxiosPromise<SavedCustomReport> {
            return localVarFp.rpzApiViewsCustomReportsSavedQueryUpdate(xCompanyId, queryId, postSavedCustomReport, options).then((request) => request(axios, basePath));
        },
        /**
         * 詳細レポートを取得するエンドポイント
         * @summary 詳細レポート
         * @param {number} xCompanyId 
         * @param {CustomReportSearch} [customReportSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsCustomReportsSearch(xCompanyId: number, customReportSearch?: CustomReportSearch, options?: any): AxiosPromise<CustomReportResult> {
            return localVarFp.rpzApiViewsCustomReportsSearch(xCompanyId, customReportSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * フィルターを指定すると着予計算に使うデータが絞り込まれる
         * @summary 着地予想を返す
         * @param {DashboardForecastFilter} [dashboardForecastFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDashboardForecastShow(dashboardForecastFilter?: DashboardForecastFilter, options?: any): AxiosPromise<DashboardForecast> {
            return localVarFp.rpzApiViewsDashboardForecastShow(dashboardForecastFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary アイテム追加
         * @param {DashboardItemCreateRequest} [dashboardItemCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDashboardItemCreate(dashboardItemCreateRequest?: DashboardItemCreateRequest, options?: any): AxiosPromise<DashboardItem> {
            return localVarFp.rpzApiViewsDashboardItemCreate(dashboardItemCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary アイテム削除
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDashboardItemDelete(itemId: number, options?: any): AxiosPromise<void> {
            return localVarFp.rpzApiViewsDashboardItemDelete(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary アイテム更新
         * @param {number} itemId 
         * @param {DashboardItemUpdateRequest} [dashboardItemUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDashboardItemUpdate(itemId: number, dashboardItemUpdateRequest?: DashboardItemUpdateRequest, options?: any): AxiosPromise<DashboardItem> {
            return localVarFp.rpzApiViewsDashboardItemUpdate(itemId, dashboardItemUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ダッシュボード取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDashboardShow(options?: any): AxiosPromise<DashboardItemListResponse> {
            return localVarFp.rpzApiViewsDashboardShow(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 新規デマンドアカウント作成
         * @param {DemandAccountCredencial} [demandAccountCredencial] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountCreate(demandAccountCredencial?: DemandAccountCredencial, options?: any): AxiosPromise<RpzApiViewsDemandAccountCreate201Response> {
            return localVarFp.rpzApiViewsDemandAccountCreate(demandAccountCredencial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DemandAccountCreativeの論理復活
         * @param {number} xCompanyId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountCreativeActivate(xCompanyId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any): AxiosPromise<Array<DemandAccountCreativeDetail>> {
            return localVarFp.rpzApiViewsDemandAccountCreativeActivate(xCompanyId, demandAccountCreativeIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DemandAccountCreativeの論理削除
         * @param {number} xCompanyId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountCreativeDelete(xCompanyId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any): AxiosPromise<Array<DemandAccountCreativeDetail>> {
            return localVarFp.rpzApiViewsDemandAccountCreativeDelete(xCompanyId, demandAccountCreativeIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DemandAccountCreative一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountCreativeList(options?: any): AxiosPromise<Array<DemandAccountCreativeDetail>> {
            return localVarFp.rpzApiViewsDemandAccountCreativeList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 検索条件に合致したデマンドアカウントクリエイティブ一覧取得
         * @param {number} xCompanyId 
         * @param {DemandAccountCreativeRelationTo} relationTo 
         * @param {DemandAccountCreativeStatus} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountCreativeRelationSearch(xCompanyId: number, relationTo: DemandAccountCreativeRelationTo, status: DemandAccountCreativeStatus, options?: any): AxiosPromise<Array<DemandAccountCreative>> {
            return localVarFp.rpzApiViewsDemandAccountCreativeRelationSearch(xCompanyId, relationTo, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DemandAccountCreativeのChannelTypeを変更する
         * @param {number} xCompanyId 
         * @param {DemandAccountCreativeChannelType} [demandAccountCreativeChannelType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountCreativeUpdateChannelType(xCompanyId: number, demandAccountCreativeChannelType?: DemandAccountCreativeChannelType, options?: any): AxiosPromise<Array<DemandAccountCreativeDetail>> {
            return localVarFp.rpzApiViewsDemandAccountCreativeUpdateChannelType(xCompanyId, demandAccountCreativeChannelType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DemandAccountCreativeのDeviceTypeを変更する
         * @param {DemandAccountCreativeDeviceType} [demandAccountCreativeDeviceType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountCreativeUpdateDeviceType(demandAccountCreativeDeviceType?: DemandAccountCreativeDeviceType, options?: any): AxiosPromise<Array<DemandAccountCreativeDetail>> {
            return localVarFp.rpzApiViewsDemandAccountCreativeUpdateDeviceType(demandAccountCreativeDeviceType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DemandAccountCreativeのOSを変更する
         * @param {DemandAccountCreativeOsType} [demandAccountCreativeOsType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountCreativeUpdateOsType(demandAccountCreativeOsType?: DemandAccountCreativeOsType, options?: any): AxiosPromise<Array<DemandAccountCreative>> {
            return localVarFp.rpzApiViewsDemandAccountCreativeUpdateOsType(demandAccountCreativeOsType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary デマンドアカウントの削除
         * @param {number} demandAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountDelete(demandAccountId: number, options?: any): AxiosPromise<void> {
            return localVarFp.rpzApiViewsDemandAccountDelete(demandAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary デマンド設定画面で表示するデマンドアカウント一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountGetList(options?: any): AxiosPromise<Array<DemandAccountWithScraperStatus>> {
            return localVarFp.rpzApiViewsDemandAccountGetList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary デマンド登録・編集の際に選択可能なデマンドアカウント一覧
         * @param {number} xCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountGetSettingList(xCompanyId: number, options?: any): AxiosPromise<Array<DemandFormConfig>> {
            return localVarFp.rpzApiViewsDemandAccountGetSettingList(xCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary デマンド設定画面で編集ボタンを押した時のデマンドアカウント情報
         * @param {number} xCompanyId 
         * @param {number} demandAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountShowDetail(xCompanyId: number, demandAccountId: number, options?: any): AxiosPromise<DemandAccountDetail> {
            return localVarFp.rpzApiViewsDemandAccountShowDetail(xCompanyId, demandAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary デマンドアカウント情報の更新
         * @param {number} demandAccountId 
         * @param {DemandAccountUpdateInfo} [demandAccountUpdateInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandAccountUpdate(demandAccountId: number, demandAccountUpdateInfo?: DemandAccountUpdateInfo, options?: any): AxiosPromise<RpzApiViewsDemandAccountUpdate200Response> {
            return localVarFp.rpzApiViewsDemandAccountUpdate(demandAccountId, demandAccountUpdateInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 媒体社に紐づくデマンド一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsDemandList(options?: any): AxiosPromise<Array<Demand>> {
            return localVarFp.rpzApiViewsDemandList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GAM枠とデマンド枠のマッピング(gam_demand_creativeの作成)
         * @param {number} xCompanyId 
         * @param {number} gamLineItemCreativeId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsGamCreateGamDemandCreative(xCompanyId: number, gamLineItemCreativeId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any): AxiosPromise<Array<DemandAccountCreative>> {
            return localVarFp.rpzApiViewsGamCreateGamDemandCreative(xCompanyId, gamLineItemCreativeId, demandAccountCreativeIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GAM枠とデマンド枠のマッピング解除(gam_demand_creativeの削除)
         * @param {number} xCompanyId 
         * @param {number} gamLineItemCreativeId 
         * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsGamDeleteGamDemandCreative(xCompanyId: number, gamLineItemCreativeId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any): AxiosPromise<Array<DemandAccountCreative>> {
            return localVarFp.rpzApiViewsGamDeleteGamDemandCreative(xCompanyId, gamLineItemCreativeId, demandAccountCreativeIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GAM申込情報に紐づくクリエイティブ一覧を取得
         * @param {number} xCompanyId 
         * @param {number} gamLineItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsGamListGamLineItemCreatives(xCompanyId: number, gamLineItemId: number, options?: any): AxiosPromise<GamLineItemCreativeDetailRelation> {
            return localVarFp.rpzApiViewsGamListGamLineItemCreatives(xCompanyId, gamLineItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary イールド設定の更新
         * @param {Array<GamLineItemYieldSetting>} gamLineItemYieldSetting 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsGamYieldUpdate(gamLineItemYieldSetting: Array<GamLineItemYieldSetting>, options?: any): AxiosPromise<Array<GamLineItemDetail>> {
            return localVarFp.rpzApiViewsGamYieldUpdate(gamLineItemYieldSetting, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary デフォルトダッシュボード数値の取得
         * @param {DemandReportSearchStruct} [demandReportSearchStruct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsReportSearch(demandReportSearchStruct?: DemandReportSearchStruct, options?: any): AxiosPromise<Array<DashboardDemandReportStruct>> {
            return localVarFp.rpzApiViewsReportSearch(demandReportSearchStruct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary カテゴリ一覧
         * @param {number} xCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsReportStatusList(xCompanyId: number, options?: any): AxiosPromise<Array<DemandAccountReportStatus>> {
            return localVarFp.rpzApiViewsReportStatusList(xCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary サイトクリエイティブの削除
         * @param {number} xCompanyId 
         * @param {SiteCreativeRequest} siteCreativeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsSiteDeleteSiteCreative(xCompanyId: number, siteCreativeRequest: SiteCreativeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.rpzApiViewsSiteDeleteSiteCreative(xCompanyId, siteCreativeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary サイトクリエイティブの更新
         * @param {number} xCompanyId 
         * @param {number} siteId 
         * @param {SiteCreativeRequest} [siteCreativeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsSiteUpsertSiteCreative(xCompanyId: number, siteId: number, siteCreativeRequest?: SiteCreativeRequest, options?: any): AxiosPromise<Array<DemandAccountCreativeDetail>> {
            return localVarFp.rpzApiViewsSiteUpsertSiteCreative(xCompanyId, siteId, siteCreativeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ログインしているアカウントに関する情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rpzApiViewsUserGetMe(options?: any): AxiosPromise<UserStruct> {
            return localVarFp.rpzApiViewsUserGetMe(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary 分析機能ダッシュボードの埋め込みURLを取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsAnalyticsFeatureGetDashboard(options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsAnalyticsFeatureGetDashboard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * BIDSTRAPレポートに紐づくGAM広告ユニット一覧を取得する
     * @summary BIDSTRAPレポートに紐づくGAM広告ユニット一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsBidStrapGamAdUnits(options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsBidStrapGamAdUnits(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * BIDSTRAPレポートをCSVで取得するエンドポイント
     * @summary BIDSTRAPレポートのCSVダウンロード
     * @param {BidStrapReportSearch} [bidStrapReportSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsBidStrapReportsDownload(bidStrapReportSearch?: BidStrapReportSearch, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsBidStrapReportsDownload(bidStrapReportSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * BIDSTRAPレポートを取得するエンドポイント
     * @summary BIDSTRAPレポート
     * @param {BidStrapReportSearch} [bidStrapReportSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsBidStrapReportsFetch(bidStrapReportSearch?: BidStrapReportSearch, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsBidStrapReportsFetch(bidStrapReportSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * BIDSTRAPレポートに紐づくサイト一覧を取得する
     * @summary BIDSTRAPレポートに紐づくサイト一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsBidStrapSites(options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsBidStrapSites(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary カテゴリ作成
     * @param {RpzApiViewsCategoryCreateRequest} [rpzApiViewsCategoryCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsCategoryCreate(rpzApiViewsCategoryCreateRequest?: RpzApiViewsCategoryCreateRequest, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsCategoryCreate(rpzApiViewsCategoryCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary カテゴリの削除
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsCategoryDelete(categoryId: number, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsCategoryDelete(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary カテゴリクリエイティブの削除
     * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsCategoryDeleteCategoryCreative(demandAccountCreativeIds: DemandAccountCreativeIds, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsCategoryDeleteCategoryCreative(demandAccountCreativeIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary カテゴリクリエイティブ2の削除
     * @param {number} xCompanyId 
     * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsCategoryDeleteCategoryCreative2(xCompanyId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsCategoryDeleteCategoryCreative2(xCompanyId, demandAccountCreativeIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary カテゴリ一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsCategoryList(options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsCategoryList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary カテゴリの更新
     * @param {number} categoryId 
     * @param {CategoryUpdateRequest} categoryUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsCategoryUpdate(categoryId: number, categoryUpdateRequest: CategoryUpdateRequest, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsCategoryUpdate(categoryId, categoryUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary カテゴリクリエイティブの更新
     * @param {number} categoryId 
     * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsCategoryUpdateCategoryCreative(categoryId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsCategoryUpdateCategoryCreative(categoryId, demandAccountCreativeIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary カテゴリクリエイティブ2の更新
     * @param {number} xCompanyId 
     * @param {number} categoryId 
     * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsCategoryUpdateCategoryCreative2(xCompanyId: number, categoryId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsCategoryUpdateCategoryCreative2(xCompanyId, categoryId, demandAccountCreativeIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CSVインポート可能なデマンドに属するデマンドアカウントの一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsCsvImportGetDemandAccounts(options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsCsvImportGetDemandAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CSV ファイルを渡してデマンドレポートを取り込む
     * @param {number} [demandAccountId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsCsvImportPost(demandAccountId?: number, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsCsvImportPost(demandAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 詳細レポートをCSVで取得するエンドポイント
     * @summary 詳細レポートのCSVダウンロード
     * @param {number} xCompanyId 
     * @param {CustomReportSearch} [customReportSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsCustomReportsDownload(xCompanyId: number, customReportSearch?: CustomReportSearch, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsCustomReportsDownload(xCompanyId, customReportSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタムレポート保存クエリを作成します
     * @summary カスタムレポート保存クエリの作成
     * @param {number} xCompanyId 
     * @param {PostSavedCustomReport} postSavedCustomReport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsCustomReportsSavedQueryCreate(xCompanyId: number, postSavedCustomReport: PostSavedCustomReport, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsCustomReportsSavedQueryCreate(xCompanyId, postSavedCustomReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタムレポート保存クエリを削除します
     * @summary カスタムレポート保存クエリ削除
     * @param {number} xCompanyId 
     * @param {number} queryId 削除対象のクエリID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsCustomReportsSavedQueryDelete(xCompanyId: number, queryId: number, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsCustomReportsSavedQueryDelete(xCompanyId, queryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary テーブル・グラフ表示用のデータ取得。Highcharts用に整形したデータを返す
     * @param {number} queryId 
     * @param {CustomReportRequestFromDashboard} [customReportRequestFromDashboard] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsCustomReportsSavedQueryFetchDataHighcharts(queryId: number, customReportRequestFromDashboard?: CustomReportRequestFromDashboard, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsCustomReportsSavedQueryFetchDataHighcharts(queryId, customReportRequestFromDashboard, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタムレポート保存クエリの一覧を返します
     * @summary カスタムレポート保存クエリ一覧
     * @param {number} xCompanyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsCustomReportsSavedQueryList(xCompanyId: number, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsCustomReportsSavedQueryList(xCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタムレポート保存クエリの詳細(dimensionやindicator,filterなど)を返します
     * @summary カスタムレポート保存クエリ詳細
     * @param {number} xCompanyId 
     * @param {number} queryId 表示対象のクエリID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsCustomReportsSavedQueryShow(xCompanyId: number, queryId: number, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsCustomReportsSavedQueryShow(xCompanyId, queryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタムレポート保存クエリを更新します
     * @summary カスタムレポート保存クエリ更新
     * @param {number} xCompanyId 
     * @param {number} queryId 更新対象のクエリID
     * @param {PostSavedCustomReport} [postSavedCustomReport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsCustomReportsSavedQueryUpdate(xCompanyId: number, queryId: number, postSavedCustomReport?: PostSavedCustomReport, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsCustomReportsSavedQueryUpdate(xCompanyId, queryId, postSavedCustomReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 詳細レポートを取得するエンドポイント
     * @summary 詳細レポート
     * @param {number} xCompanyId 
     * @param {CustomReportSearch} [customReportSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsCustomReportsSearch(xCompanyId: number, customReportSearch?: CustomReportSearch, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsCustomReportsSearch(xCompanyId, customReportSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * フィルターを指定すると着予計算に使うデータが絞り込まれる
     * @summary 着地予想を返す
     * @param {DashboardForecastFilter} [dashboardForecastFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsDashboardForecastShow(dashboardForecastFilter?: DashboardForecastFilter, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsDashboardForecastShow(dashboardForecastFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary アイテム追加
     * @param {DashboardItemCreateRequest} [dashboardItemCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsDashboardItemCreate(dashboardItemCreateRequest?: DashboardItemCreateRequest, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsDashboardItemCreate(dashboardItemCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary アイテム削除
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsDashboardItemDelete(itemId: number, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsDashboardItemDelete(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary アイテム更新
     * @param {number} itemId 
     * @param {DashboardItemUpdateRequest} [dashboardItemUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsDashboardItemUpdate(itemId: number, dashboardItemUpdateRequest?: DashboardItemUpdateRequest, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsDashboardItemUpdate(itemId, dashboardItemUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ダッシュボード取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsDashboardShow(options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsDashboardShow(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 新規デマンドアカウント作成
     * @param {DemandAccountCredencial} [demandAccountCredencial] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsDemandAccountCreate(demandAccountCredencial?: DemandAccountCredencial, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsDemandAccountCreate(demandAccountCredencial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DemandAccountCreativeの論理復活
     * @param {number} xCompanyId 
     * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsDemandAccountCreativeActivate(xCompanyId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsDemandAccountCreativeActivate(xCompanyId, demandAccountCreativeIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DemandAccountCreativeの論理削除
     * @param {number} xCompanyId 
     * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsDemandAccountCreativeDelete(xCompanyId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsDemandAccountCreativeDelete(xCompanyId, demandAccountCreativeIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DemandAccountCreative一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsDemandAccountCreativeList(options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsDemandAccountCreativeList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 検索条件に合致したデマンドアカウントクリエイティブ一覧取得
     * @param {number} xCompanyId 
     * @param {DemandAccountCreativeRelationTo} relationTo 
     * @param {DemandAccountCreativeStatus} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsDemandAccountCreativeRelationSearch(xCompanyId: number, relationTo: DemandAccountCreativeRelationTo, status: DemandAccountCreativeStatus, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsDemandAccountCreativeRelationSearch(xCompanyId, relationTo, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DemandAccountCreativeのChannelTypeを変更する
     * @param {number} xCompanyId 
     * @param {DemandAccountCreativeChannelType} [demandAccountCreativeChannelType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsDemandAccountCreativeUpdateChannelType(xCompanyId: number, demandAccountCreativeChannelType?: DemandAccountCreativeChannelType, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsDemandAccountCreativeUpdateChannelType(xCompanyId, demandAccountCreativeChannelType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DemandAccountCreativeのDeviceTypeを変更する
     * @param {DemandAccountCreativeDeviceType} [demandAccountCreativeDeviceType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsDemandAccountCreativeUpdateDeviceType(demandAccountCreativeDeviceType?: DemandAccountCreativeDeviceType, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsDemandAccountCreativeUpdateDeviceType(demandAccountCreativeDeviceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DemandAccountCreativeのOSを変更する
     * @param {DemandAccountCreativeOsType} [demandAccountCreativeOsType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsDemandAccountCreativeUpdateOsType(demandAccountCreativeOsType?: DemandAccountCreativeOsType, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsDemandAccountCreativeUpdateOsType(demandAccountCreativeOsType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary デマンドアカウントの削除
     * @param {number} demandAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsDemandAccountDelete(demandAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsDemandAccountDelete(demandAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary デマンド設定画面で表示するデマンドアカウント一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsDemandAccountGetList(options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsDemandAccountGetList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary デマンド登録・編集の際に選択可能なデマンドアカウント一覧
     * @param {number} xCompanyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsDemandAccountGetSettingList(xCompanyId: number, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsDemandAccountGetSettingList(xCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary デマンド設定画面で編集ボタンを押した時のデマンドアカウント情報
     * @param {number} xCompanyId 
     * @param {number} demandAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsDemandAccountShowDetail(xCompanyId: number, demandAccountId: number, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsDemandAccountShowDetail(xCompanyId, demandAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary デマンドアカウント情報の更新
     * @param {number} demandAccountId 
     * @param {DemandAccountUpdateInfo} [demandAccountUpdateInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsDemandAccountUpdate(demandAccountId: number, demandAccountUpdateInfo?: DemandAccountUpdateInfo, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsDemandAccountUpdate(demandAccountId, demandAccountUpdateInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 媒体社に紐づくデマンド一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsDemandList(options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsDemandList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GAM枠とデマンド枠のマッピング(gam_demand_creativeの作成)
     * @param {number} xCompanyId 
     * @param {number} gamLineItemCreativeId 
     * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsGamCreateGamDemandCreative(xCompanyId: number, gamLineItemCreativeId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsGamCreateGamDemandCreative(xCompanyId, gamLineItemCreativeId, demandAccountCreativeIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GAM枠とデマンド枠のマッピング解除(gam_demand_creativeの削除)
     * @param {number} xCompanyId 
     * @param {number} gamLineItemCreativeId 
     * @param {DemandAccountCreativeIds} demandAccountCreativeIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsGamDeleteGamDemandCreative(xCompanyId: number, gamLineItemCreativeId: number, demandAccountCreativeIds: DemandAccountCreativeIds, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsGamDeleteGamDemandCreative(xCompanyId, gamLineItemCreativeId, demandAccountCreativeIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GAM申込情報に紐づくクリエイティブ一覧を取得
     * @param {number} xCompanyId 
     * @param {number} gamLineItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsGamListGamLineItemCreatives(xCompanyId: number, gamLineItemId: number, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsGamListGamLineItemCreatives(xCompanyId, gamLineItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary イールド設定の更新
     * @param {Array<GamLineItemYieldSetting>} gamLineItemYieldSetting 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsGamYieldUpdate(gamLineItemYieldSetting: Array<GamLineItemYieldSetting>, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsGamYieldUpdate(gamLineItemYieldSetting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary デフォルトダッシュボード数値の取得
     * @param {DemandReportSearchStruct} [demandReportSearchStruct] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsReportSearch(demandReportSearchStruct?: DemandReportSearchStruct, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsReportSearch(demandReportSearchStruct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary カテゴリ一覧
     * @param {number} xCompanyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsReportStatusList(xCompanyId: number, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsReportStatusList(xCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary サイトクリエイティブの削除
     * @param {number} xCompanyId 
     * @param {SiteCreativeRequest} siteCreativeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsSiteDeleteSiteCreative(xCompanyId: number, siteCreativeRequest: SiteCreativeRequest, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsSiteDeleteSiteCreative(xCompanyId, siteCreativeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary サイトクリエイティブの更新
     * @param {number} xCompanyId 
     * @param {number} siteId 
     * @param {SiteCreativeRequest} [siteCreativeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsSiteUpsertSiteCreative(xCompanyId: number, siteId: number, siteCreativeRequest?: SiteCreativeRequest, options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsSiteUpsertSiteCreative(xCompanyId, siteId, siteCreativeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ログインしているアカウントに関する情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rpzApiViewsUserGetMe(options?: any) {
        return DefaultApiFp(this.configuration).rpzApiViewsUserGetMe(options).then((request) => request(this.axios, this.basePath));
    }
}
