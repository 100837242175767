import {
  CustomReportChartRecord,
  CustomReportDimension,
  CustomReportFilters,
  CustomReportRequestFromDashboardAdditionalFilters,
  CustomReportResult,
  DemandAccountDetail as openAPIDemandAccountDetail,
  DemandFormConfig,
} from "../api-client/model";
import { AnalyticsFeaturePublishType } from "../api-client/model/analytics-feature-publish-type";

export namespace Domain {
  export type SystemRole = "admin" | "people";
  export type CompanyRole = "owner" | "worker";
  export type UserRole = Exclude<SystemRole, "people"> | CompanyRole;
  export interface User {
    id: number;
    name: string;
    original_id: string;
    company_attributes: Domain.CompanyAttribute[];
    user_system_role: {
      name: SystemRole;
    };
  }

  export interface CompanyAttribute {
    company: Company;
    user_company_role: {
      name: CompanyRole;
    };
  }

  export interface DemandAccountSettingLimit {
    limit: number;
  }

  export interface Company {
    id: number;
    name: string;
    demand_account_setting_limit: Domain.DemandAccountSettingLimit | null;
    // 将来的にはOpenAPIに全て寄せていく方針なのでOpenAPIで自動生成される型を利用している。
    analytics_feature_publish_type: AnalyticsFeaturePublishType;
  }
  export interface Demand {
    id: number;
    name: string;
  }
  export interface DemandAccount {
    id: number;
    demand: Domain.Demand;
    scraper_enabled: boolean;
  }

  export interface DemandAccountWithScraperStatus {
    id: number;
    demand: Domain.Demand;
    scraper_enabled: boolean;
    // 0: unknown, 1: running, 2: finished, 3: failed, 4: authentication_error, 5: invalid_credential
    scraper_status: 0 | 1 | 2 | 3 | 4 | 5;
  }

  export type DemandAccountDetail = openAPIDemandAccountDetail;

  export type DemandForm = DemandFormConfig;

  export type Error = {
    code: number;
    message: string;
  };

  export type Site = {
    id: number;
    name: string;
  };

  export type DemandAccountCreative = {
    id: string;
    demand_account_id: number;
    original_id: string;
    original_name: string | null;
    demand: Domain.Demand;
    channel_type_name: ChannelType;
    device_type_name: DeviceType;
    os_type_name: OsType;
    hb_demand_type_name: string;
    is_active: boolean;
  };

  export type DemandAccountCreativeDetail = {
    demand_account_creative: DemandAccountCreative;
    site: Site | null;
    category: Category | null;
    category2: Category | null;
    gam_ad_unit: Omit<Domain.GamAdUnit, "original_ad_unit_id"> | null;
  };

  export type DemandAccountCreativeStatus = "unmapped";

  export type GamAdUnit = {
    id: number;
    original_ad_unit_id: string;
    original_ad_unit_name: string;
  };

  export type GamLineItem = {
    id: number;
    original_line_item_id: number;
    original_line_item_name: string;
  };

  export type YieldType =
    | "cpm" // 価格優先イールド
    | "suspend" // イールドを停止
    | "not_available" // イールド不可な状態
    | "cpm_only_update_creative_weight"; // 価格優先イールド(creativeの配信比率のみイールド)

  export type GamLineItemYieldSetting = {
    yield_type_name: YieldType;
  };

  export type GamLineItemDetail = {
    id: number;
    original_line_item_id: number;
    original_line_item_name: string;
    gam_line_item_yield_setting: GamLineItemYieldSetting | null;
  };

  export type GamAdCreative = {
    id: number;
    original_line_item_creative_id: number;
    original_line_item_creative_name: string;
    demand_account_creatives: DemandAccountCreative[];
  };

  export type GamAdCreativeDetail = {
    id: number;
    original_line_item_creative_id: number;
    original_line_item_creative_name: string;
    demand_account_creatives: DemandAccountCreative[];
    is_mappable: boolean;
  };

  export type GamFlattenedHierarchy = {
    id: number;
    original_line_item_creative_id: number;
    original_line_item_creative_name: string;
    is_mappable: boolean;
    gam_line_item: GamLineItem;
    gam_ad_unit: GamAdUnit;
  };

  export type ChartUnitType = "normal" | "price";

  export type Dashboard = {
    total_imp: number;
    total_price: number;
    average_ecpm: number;
    prices: ChartData[];
    imps: ChartData[];
    ecpms: ChartData[];
    total_ecpms: ChartData[];
  };

  export type DashboardItemDisplayType = "table" | "bar" | "line";

  export type DashboardItem = {
    id: number;
    query_id: number;
    order: number;
    size: number;
    title: string;
    display_type: DashboardItemDisplayType;
  };

  export type Forecast = {
    price: number;
    imps: number;
    status: "ok" | "insufficient_data" | "error" | "loading"; // error, loadingはクライアントのみ
  };

  export interface ChartData extends CustomReportChartRecord {
    color?: string;
    type?: "line" | "column";
  }

  export type DetailReport = CustomReportResult;

  export type BidStrapReport<T = {}> = {
    all_count: number;
    headers: Array<keyof Partial<BidStrapReportRecord>>;
    records: (Partial<BidStrapReportRecord> & T)[];
  };

  export type AdditionalFilter = CustomReportRequestFromDashboardAdditionalFilters;

  export type SavedQuery = {
    id: number;
    user: Domain.User;
    custom_report_period: Exclude<
      Domain.DateRangeType,
      "custom_range" | "custom_single"
    >;
    custom_report_group: GroupType;
    name: string;
    created_at: string; // YYYY-MM-DD HH:mm:ss
  };

  export type SavedQueryDetail = {
    custom_report_period: Exclude<
      Domain.DateRangeType,
      "custom_range" | "custom_single"
    >;
    custom_report_group: GroupType;
    custom_report_dimensions: ReportDimensionType[];
    custom_report_indicators: (keyof ReportIndicator)[];
    saved_custom_report_filters: Domain.ReportFilters;
  };

  export type ReportRecord = ReportIndicator & ReportDimension;

  export type ReportDimension = {
    date: string; // YYYY-MM-DD
    site_name: string;
    category_name: string;
    category2_name: string;
    ad_unit_name: string;
    demand_unit_name: string;
    demand_name: string;
    device_type_name: string;
    channel_type_name: string;
    hb_demand_type_name: string;
    os_type_name: string;
  };

  export const reportDimensions = [
    "date", // YYYY-MM-DD
    "site_name",
    "category_name",
    "category2_name",
    "ad_unit_name",
    "demand_unit_name",
    "demand_name",
    "device_type_name",
    "channel_type_name",
    "hb_demand_type_name",
    "os_type_name",
  ];

  export type ReportDimensionType = CustomReportDimension | "none";

  export type ReportIndicator = DemandIndicator & GamIndicator;

  export type DemandIndicator = {
    price: number;
    price_ratio: number;
    imp: number;
    imp_ratio: number;
    ecpm: number;
    click: number;
    ctr: number;
    vimp: number;
    view_rate: number;
    cpc: number;
  };

  export type GamIndicator = {
    gam_imp: number;
    gam_ecpm: number;
    gam_vimp: number;
    gam_view_rate: number;
    fill_rate: number;
    request: number;
    unfilled_imp: number;
    unfilled_imp_rate: number;
  };

  export type ReportIndicatorType = keyof ReportRecord;

  export type GroupType = "day" | "month" | "summary";

  export type ReportFilters = CustomReportFilters;

  export type DateRangeType =
    | "custom_range"
    | "custom_single"
    | "yesterday"
    | "this_month"
    | "last_month"
    | "this_year"
    | "last_7_days"
    | "last_30_days"
    | "last_365_days"
    | "last_730_days"
    | "last_3_months"
    | "last_6_months"
    | "last_12_months";

  export type Category = {
    id: number;
    name: string;
  };

  // ID順に並んでいる
  export const ChannelTypes = [
    "thirdparty",
    "prebid",
    "openbidding",
    "tam",
    "adx",
    "firstparty",
    "prebid_flux",
    "prebid_bidstrap",
    "admob_network",
    "admob_network_waterfall",
  ] as const;

  export type ChannelType = typeof ChannelTypes[number];

  export type DeviceType = "unknown" | "sp_web" | "pc_web" | "sp_amp" | "app";

  export type OsType = "none" | "unknown" | "android" | "ios" | "ipad_os";

  export const osTypes: { value: Domain.OsType; name: string }[] = [
    { value: "unknown", name: "unknown" },
    { value: "android", name: "Android" },
    { value: "ios", name: "iOS" },
    { value: "ipad_os", name: "iPadOS" },
  ];

  export type Information = {
    id: number;
    title: string;
    message: string;
    date: string;
    is_already_read: boolean;
  };

  export type HbDemandType = {
    id: number;
    name: string;
  };

  export type MtDemandType = "adx";

  export type MtDimensionType = "dsp" | "advertiser" | "domain";

  export type SCRatioIndicatorType = keyof SaleCompositionRatioData;

  export type SaleCompositionRatioData = {
    name: string;
    ratio: number;
    ecpm: number;
  };

  export type MarketTrendEcpm = {
    ecpms: ChartData[];
  };

  export type MarketTrendSalesCompositionRatio<T = {}> = {
    dimension_type_name: MtDimensionType;
    market: {
      all_count: number;
      records: Partial<SaleCompositionRatioData> & T[];
    };
    mine: {
      all_count: number;
      records: Partial<SaleCompositionRatioData> & T[];
    };
  };

  export type BidStrapReportDimensionType =
    | "bidder"
    | "ad_unit_name"
    | "site_name";

  export type BidStrapReportIndicator = {
    auction_count: number;
    nobid_count: number;
    prebid_win_count: number;
    prebid_win_rate: number;
    bid_win_count: number;
    bid_win_rate: number;
    valid_bid_count: number;
    avg_cpm: number;
    prebid_win_avg_cpm: number;
    gam_win_avg_cpm: number;
    timeout_count: number;
    demand_price: number;
    demand_imps: number;
    demand_clicks: number;
  };

  export type BidStrapReportRecord = BidStrapReportIndicator & {
    date: string; // YYYY-MM-DD
    bidder: string;
    ad_unit_name: string;
    site_name: string;
  };

  export type BidStrapReportFilters = {
    bidder_ids?: number[];
    ad_unit_ids?: number[];
    site_ids?: number[];
  };

  export type BidStrapReportIndicatorType = keyof BidStrapReportRecord;

  export type AnalyticsFeatureDashboardUrl = string;
}
